import { Buffer } from 'buffer'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { loginService, signUpService, logoutService, updatePasswordService, forgetPasswordService, forgetVerifyPasswordService, verifyEmailService, updateEmailService, resendEmailToken, loginWithSocialService } from 'network/services/auth.service'
import { signIn, signOut } from 'helpers/cookie.helpers'
import { ROUTE_PATHS } from 'constants/index'
import { fetchUserInformation } from './user.thunk'
import { setUserData } from 'redux-thunk/redux/slices/user.slice'
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { setForgotPassword, setLoginData, setShowResetPasswordPopup } from 'redux-thunk/redux/slices/auth.slice'
import { setUserBonus } from 'redux-thunk/redux/slices/bonus.slice'
import { setEmailCookie, removeEmailCookie } from 'helpers/cookie.helpers'

/**
 * User Login Thunk
 */

export const userLogin = createAsyncThunk('user/login',
  async ({ userNameOrEmail, password, deviceType, onLoginSuccess, OnLoginError }, thunkApi) => {
    password = Buffer.from(password).toString('base64')

    try {
      const res = await loginService({ email: userNameOrEmail, password, deviceType })
      setEmailCookie(userNameOrEmail);
      signIn({
        token: res.accessToken,
        email: userNameOrEmail,
      })
      onLoginSuccess()
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      OnLoginError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const userSignUp = createAsyncThunk('user/signup',
  async ({ userDetails, t, onSignupError }, thunkApi) => {
    try {
      const res = await signUpService(userDetails)
      thunkApi.dispatch(setShowLoginPopup(false))
      return res
    } catch (error) {
      onSignupError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

/**
 * Operator Logout Thunk
 */
export const userLogout = createAsyncThunk('user/logout',
  async ({ history }, thunkApi) => {
    try {
      const res = await logoutService()
      signOut()
      thunkApi.dispatch(setLoginData(null))
      thunkApi.dispatch(setUserData(null))
      thunkApi.dispatch(setUserBonus([]))
      history.replace(ROUTE_PATHS.HOME)
      removeEmailCookie()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

/**
 * Operator Change Password Thunk
 */
export const updatePassword = createAsyncThunk('update/password',
  async ({ password, newPassword, onchangePasswordError }, thunkApi) => {
    password = Buffer.from(password).toString('base64')
    newPassword = Buffer.from(newPassword).toString('base64')
    try {
      const res = await updatePasswordService({ password, newPassword })
      return res
    } catch (error) {
      onchangePasswordError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const forgetPassword = createAsyncThunk('forget/password',
  async ({ email, setTokenSentStatus }, thunkApi) => {
    try {
      const res = await forgetPasswordService({ email })
      setTokenSentStatus(true)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const VerifyPasswordToken = createAsyncThunk('verifyforget/password',
  async (values, thunkApi) => {
    const { history } = values
    const payload = { ...values }
    delete payload.history

    try {
      const res = await forgetVerifyPasswordService(payload)
      thunkApi.dispatch(setShowResetPasswordPopup(false))
      history.replace('/')

      return res
    } catch (error) {
      values?.onResetPasswordError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const verifyEmail = createAsyncThunk('verifyEmail/emailToken',
  async (payload, thunkApi) => {
    try {
      const res = await verifyEmailService(payload)
      thunkApi.dispatch(setForgotPassword(false))
      thunkApi.dispatch(setShowLoginPopup(false))
      // openSuccessToaster({ message: 'Verification mail successfully sent to your registered email' })
      return res
    } catch (error) {
      payload?.onVerifyEmailError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const updateEmail = createAsyncThunk('update/email',
  async ({ newEmail, oldEmail }, thunkApi) => {
    try {
      const res = await updateEmailService({ newEmail, oldEmail })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const resendVerificationLink = createAsyncThunk('update/email',
  async ({ email, setLinkStatus }, thunkApi) => {
    try {
      const res = await resendEmailToken({ email })
      setLinkStatus(true)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const userSocialLogin = createAsyncThunk('user/socialLogin',
  async ({ data, onLoginSuccess }, thunkApi) => {
    try {
      const res = await loginWithSocialService({ data })
      signIn({
        token: res.accessToken
      })
      onLoginSuccess()
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })
