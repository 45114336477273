import { createSlice } from '@reduxjs/toolkit'
import { getAllBonus, getBonus, getPromotions, getUserBonus, getBonusDetails } from 'redux-thunk/thunk/bonus.thunk'

const initialState = {
  allBonus: null,
  userBonus: null,
  loyaltyLevel: null,
  bonusDetails: null,
  promotionsLoader: false,
  promotionsData: [],
  allBonusDetails: null
}

const {
  actions: {
    setUserBonus,
    getLoyaltyLevelStart,
    setPromotionsLoader
  },
  reducer
} = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    setUserBonus: (state, action) => ({
      ...state,
      userBonus: action.payload
    }),
    getLoyaltyLevelStart: (state, action) => ({
      ...state,
      loyaltyLevel: action.payload
    }),
    setPromotionsLoader: (state, action) => ({
      ...state,
      promotionsLoader: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBonus.fulfilled, (state, action) => {
        return {
          ...state,
          allBonus: action.payload
        }
      })
      .addCase(getUserBonus.fulfilled, (state, action) => {
        return {
          ...state,
          userBonus: action.payload.userBonus
        }
      })
      .addCase(getPromotions.fulfilled, (state, action) => {
        return {
          ...state,
          promotionsLoader: false,
          promotionsData: action.payload
        }
      })
      .addCase(getBonus.fulfilled, (state, action) => {
        return {
          ...state,
          allBonusDetails: action.payload
        }
      })
      .addCase(getPromotions.rejected, (state, action) => {
        return {
          ...state,
          promotionsLoader: false,
          promotionsData: action.payload
        }
      })
      .addCase(getPromotions.pending, (state, action) => {
        return {
          ...state,
          promotionsLoader: true,
          promotionsData: action.payload
        }
      })
      .addCase(getBonusDetails.fulfilled, (state, action) => {
        return {
          ...state,
          bonusDetails: action.payload.bonusDetails // Ask BE to fix structure
        }
      })
  }
})

export default reducer
export { setUserBonus, getLoyaltyLevelStart, setPromotionsLoader }
