import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Collapse,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setChangeHamburgerIconClass } from "redux-thunk/redux/slices/user.slice";

const SubMenu = ({ item, isAuthenticated, defaultExpanded = false }) => {
  const [subnav, setSubnav] = useState(defaultExpanded);
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const showSubnav = () => {
    setSubnav(!subnav);
    dispatch(setChangeHamburgerIconClass("sidebar-open"));
  };

  return (
    <MenuItem
      className={clsx({
        "active-link": subnav,
      })}
    >
      <Box className="inner-menu-list">
        <Tooltip
          title={item.title}
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          placement="right"
        >
          <Box
            className={clsx("nav-link sidebar-dropdown", {
              active: item.isActive,
            })}
            onClick={showSubnav}
          >
            <ListItemIcon>
              {React.cloneElement(item.icon, { className: "menu-icon" })}
            </ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
            <IconButton
              aria-label="arrow"
              className="link-hidden"
              sx={{ color: "#57535E", paddingTop: 0, paddingBottom: 0 }}
            >
              <ChevronRightIcon
                className="chevron"
                style={{
                  transform: subnav ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "0.3s all",
                }}
              />
            </IconButton>
          </Box>
        </Tooltip>
        <Collapse in={subnav} timeout="auto" unmountOnExit>
          <MenuList
            className="inner-to-inner-list link-hidden"
            style={{
              position: "relative",
              overflow: "hidden",
              background: "#0D1B32",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              borderTop: "3px solid #30538E",
            }}
          >
            {item.subNav.map((nav, index) => {
              if (!isAuthenticated && nav.auth) return null;

              const pathSearch = nav.path.split("?").pop();
              const active = pathSearch === search.slice(1);

              return (
                <MenuItem
                  key={index}
                  className={clsx(`nav-link`, {
                    active,
                  })}
                  onClick={() => {
                    nav.onClick?.();
                    nav.path && history.push(nav.path);
                  }}
                  style={{
                    padding: ".5rem 0",
                  }}
                >
                  <ListItemIcon>{nav.icon}</ListItemIcon>
                  <ListItemText>{nav.title}</ListItemText>
                </MenuItem>
              );
            })}
          </MenuList>
        </Collapse>
      </Box>
    </MenuItem>
  );
};

export default SubMenu;
