import styled from '@emotion/styled';
import { Button } from '@mui/material';


export const StyledTabButton = styled(Button)(({ theme }) => {
  return {
    height: theme.spacing(6.25),
    background: theme.palette.button.mainGradient,
    borderRadius: theme.spacing(1.25),
    borderWidth: 0,
    borderColor: theme.palette.lightShade.light_02,
    color: theme.palette.primary.contrastText,
    fontSize: theme.spacing(2.25),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.button.white,
      color: theme.palette.primary.contrastTextActive,
      '& svg': {
        fill: theme.palette.primary.contrastTextActive,
      },
    },
  };
});


export const StyledMainButton = styled(Button)(({ theme }) => {
  return {
    height: theme.spacing(6.25),
    background: theme.palette.button.mainGradient,
    borderRadius: theme.spacing(1.25),
    borderWidth: theme.spacing(0.188),
    borderColor: theme.palette.lightShade.light_02,
    color: theme.palette.primary.contrastText,
    fontSize: theme.spacing(2.25),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.button.white,
      color: theme.palette.primary.contrastTextActive,
      '& svg': {
        fill: theme.palette.primary.contrastTextActive,
      },
    },
  };
});

export const StyledPrimaryButton = styled(Button)(({ theme }) => {
  return {
    height: theme.spacing(5.375),
    backgroundColor: theme.palette.button.light,
    borderRadius: theme.spacing(1.25),
    color: theme.palette.primary.contrastText,
    fontSize: theme.spacing('1rem'),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
    padding: theme.spacing(1.5, 3),
    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.button.white,
      color: theme.palette.secondary.light,
      '& svg': {
        fill: theme.palette.secondary.light,
      },
    },
  };
});

export const StyledTextButton = styled(Button)(({ theme }) => {
  return {
    height: theme.spacing(5.375),
    backgroundColor: theme.palette.button.transparent,
    borderRadius: theme.spacing(1.25),
    color: theme.palette.primary.contrastText,
    fontSize: theme.spacing('1rem'),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
    padding: theme.spacing(1.5, 3),
    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.button.transparent,
      color: theme.palette.primary.contrastTextActive,
      '& svg': {
        fill: theme.palette.primary.contrastTextActive,
      },
    },
  };
});
