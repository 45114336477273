import { Box, styled } from '@mui/material'
import checkIcon from '../../assets/icons/check.svg'

export const LoginModalStyle = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: theme.spacing(70),
    width: '100%',
    backgroundColor: '#0b0116',
    borderRadius: theme.spacing(2),
    padding: '20px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.spacing(60),
    },
    '& .signupLoginBtn': {
      border: 'none'
    },
    '& .forgot-back-btn':{
      position: 'absolute',
      top: '5px',
      left: '10px'
    },
    '& .forgot-close-btn':{
      position: 'absolute',
      top: '5px',
      right: '10px'
    },

    '&.forget-modal': {
      maxWidth: theme.spacing(70),
      padding: theme.spacing(2)
    },
    '& .hero-heading-section':{
        padding:'2rem',
    '& .hero-heading-box': {
      textAlign: 'center',
      '& .banner-heading':{
        color: '#fff',
        fontSize: '20px',
        fontWeight: '500',
        textTransform: 'uppercase'
      },
      '& .banner-link':{
        cursor:'pointer'
      },
      '& .inner-banner-heading':{
        color: theme.palette.primary.contrastText,
        fontSize: theme.spacing(1.5)
      }
    },
  },

    '& .login-signup-img': {
      [theme.breakpoints.down('lg')]: {
        display: 'none'
      },
      position: 'relative',
      '& img': {
        aspectRatio: '1',
        width: '100%',
        height: '100%'

      },
      '& .bannerHeading': {
        position: 'absolute',
        bottom: '0',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(4),
        width: '100%',
        '& .bannerText1': {
          color: theme.palette.primary.contrastText,
          fontSize: theme.spacing(4)
        },
        '& .bannerText2': {
          color: '#00A542',
          fontSize: theme.spacing(5.5),
          fontWeight: 'bold'
        },
        '& .bannerText3': {
          color: theme.palette.primary.contrastText,
          fontSize: theme.spacing(1.5)
        }
      },
      '& .slick-dots': {
        bottom: '170px',
        zIndex: '1',
        '& li': {
          '&.slick-active': {
            '& button': {
              '&:before': {
                color: theme.palette.button.activeBtn
              }
            }
          },
          '& button': {
            '&:before': {
              color: theme.palette.button.sliderBtn
            }
          }
        }
      }
    },

    '& .login-modal': {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .MuiTabs-indicator': {
        display: 'none'
      },

      '& .imagebannerlogo': {
        display: 'flex',
        justifyContent:'center',
        '& img': {
          width: '100px',
        }
      },

      '& .modal-heading': {
        textAlign: 'center',
        fontSize: theme.spacing(3.25),
        color: theme.palette.primary.contrastText
      },

      '& .login-tab': {
        marginTop: theme.spacing(3.5),
        '& .login-inner-tab': {
          'div[role=tablist]': {
            padding: theme.spacing(0),
            background: 'transparent',
            borderRadius: theme.spacing(0),
            justifyContent: 'center',
            borderBottom: '1px solid #173055',
            marginBottom: '10px',
            '& button': {
              width: '50%',
              color: theme.palette.primary.contrastTextDark,
              textTransform: 'capitalize'
            },
            '& .item-one': {
              width: '50%'
            },
            '& .item-two': {
              width: '50%'
            },
            '& .Mui-selected': {
              color: '#3F86FC',
              background: 'linear-gradient(180deg, rgba(21, 45, 84, 0) 0%, rgba(21, 45, 84, 0.74) 100%)',
              borderBottom: '3px solid #3F86FC',
              borderRadius: theme.spacing(0)
            }
          }
        },

        '& .tab-panel': {
          position: 'relative',
          background: 'transparent',
          padding: theme.spacing(0),
          maxHeight: 'calc(100vh - 235px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0',
            height: '0',
            background: 'transparent !important'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'transparent !important'
          },
          [theme.breakpoints.up('xxl')]: {
            maxHeight: 'calc(100vh - 450px)'
          }
        },

        '& .input-label': {
          color: theme.palette.primary.contrastText,
          fontSize: theme.spacing(1.75),
          fontWeight: theme.typography.fontWeightMedium,
          paddingTop: theme.spacing(1.25),
          display: 'flex',
          '& .required-indicator': {
            color: 'red'
          }
        },

        '& .login-form-group': {
          color: theme.palette.primary.contrastTextDark,

          '& .MuiInputBase-input': {
            color: theme.palette.primary.contrastText,
            padding: theme.spacing(1.5)
          },

          '& .MuiInputLabel-root': {
            fontSize: theme.spacing(1.75),
            fontWeight: theme.typography.fontWeightMedium,
            padding: theme.spacing(1.25, 0, 0, 0)
          }
        },

        '& .custom-checkbox-group': {
          paddingTop: theme.spacing(2.5),
          display: 'flex',
          alignItems: 'flex-start',
          '& .primary-dark-text': {
            color: theme.palette.primary.contrastTextDark,
            fontSize: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(0)
          },

          '& .custom-checkbox': {
            '& .custom-input': {
              appearance: 'none',
              padding: theme.spacing(1.25),
              border: `1px solid ${theme.palette.primary.contrastTextDark}`,
              borderRadius: theme.spacing(0.5),
              cursor: 'pointer',

              ':checked': {
                backgroundImage: `url(${checkIcon})`
              }
            }
          }
        },

        // 'input' : {
        //   color: '#ffffff'
        //   '&::placeholder':{
        //     color: '#ffffff'
        //   }
        // },
        '& .social-media-box': {
          gap: theme.spacing(1),
          marginTop: theme.spacing(2),
          '& .social-media-icon': {
            background: '#0d1b32',
            textAlign: 'center',
            padding: '10px',
            borderRadius: theme.spacing(0.625),
            marginBottom: '30px'
          }
        },
        '& .forgot-text': {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.spacing(2),
          a: {
            color: theme.palette.primary.contrastText,
            fontSize: theme.spacing(2),
            fontWeight: theme.typography.fontWeightMedium,
            textDecoration: 'none',
          }
        },
        '& .forgot-text2': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.spacing(2),
          marginBottom: '30px',
          a: {
            color: theme.palette.primary.contrastText,
            fontSize: theme.spacing(2),
            fontWeight: theme.typography.fontWeightMedium,
            textDecoration: 'none',
          }
        }
      },

      '.or-divider': {
        textAlign: 'center',
        padding: theme.spacing(3.5, 0, 2, 0),

        '& .custom-divider': {
          color: theme.palette.primary.contrastTextDark,
          padding: theme.spacing(0, 1),
          position: 'relative',
          '&::before': {
            content: '""',
            width: theme.spacing(10.25),
            height: theme.spacing(0.125),
            background: theme.palette.primary.contrastTextDark,
            position: 'absolute',
            top: '50%',
            left: '100%'
          },

          '&::after': {
            content: '""',
            width: theme.spacing(10.25),
            height: theme.spacing(0.125),
            background: theme.palette.primary.contrastTextDark,
            position: 'absolute',
            top: '50%',
            right: '100%'
          }
        }
      },

      '& .primary-dark-text': {
        color: theme.palette.primary.contrastTextDark,
        paddingRight: theme.spacing(1)
      }
    },

    '.text-danger': {
      color: theme.palette.danger.main,
      fontSize: theme.spacing(1.75),
      paddingTop: theme.spacing(1)
    },
    '& .input-icon': {
      color: '#aaa', /* Adjust the color based on your design */
      zIndex: '1'
    },

    '& .input-with-icon .MuiInputBase-input': {
      paddingLeft: '30px', /* Adjust this value based on the icon size and position */
    },
    '& .btn-btn': {
      color: '#fff',
    },
    '& .captcha': {
      textAlign: 'center',
      fontWeight: '300',
      color: '#667FA4',
      '& .text-captcha': {
        fontSize: '13px'
      }
    },
    '& .rake-disc-banner': {
      color: '#9FC2FD',
      fontSize: '28px',
      textTransform: 'uppercase',
      fontWeight: '600'
    },
    '& .box-banner-footer': {
      color: '#667FA4',
      fontSize: '14px',
      fontWeight: '400',
      '& .box-image-banner-footer': {
        display: 'flex',
        justifyContent: 'center'
      },
      '& img': {
        width: '80px',
      }
    }
  }
})
