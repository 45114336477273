
export const message = {
  LOGIN_SUCCESS: 'MESSAGE.LOGIN_SUCCESS',
  FORGOT_SUCCESS: 'MESSAGE.FORGOT_SUCCESS',
  WrongEAndPwd: 'MESSAGE.WrongEAndPwd',
  EMPTY_EMAIL: 'MESSAGE.EMPTY_EMAIL',
  EMPTY_PASSWORD: 'MESSAGE.EMPTY_PASSWORD',
  EMPTY_TRANSACTION_PASSWORD: 'MESSAGE.EMPTY_TRANSACTION_PASSWORD',
  INVALID_EMAIL: 'MESSAGE.INVALID_EMAIL',
  NAME_REQUIRED: 'MESSAGE.NAME_REQUIRED',
  USER_NAME_REQUIRED: 'MESSAGE.USER_NAME_REQUIRED',
  FULL_NAME_REQUIRED: 'MESSAGE.FULL_NAME_REQUIRED',
  FIRST_NAME_REQUIRED: 'MESSAGE.FIRST_NAME_REQUIRED',
  LAST_NAME_REQUIRED: 'MESSAGE.LAST_NAME_REQUIRED',
  PHONE_REQUIRED: 'MESSAGE.PHONE_REQUIRED',
  PHONE_LENGTH_REQUIRED: 'MESSAGE.PHONE_LENGTH_REQUIRED',
  ACCOUNT_NO_REQUIRED: 'MESSAGE.ACCOUNT_NO_REQUIRED',
  ACCOUNT_HOLDER_REQUIRED: 'MESSAGE.ACCOUNT_HOLDER_REQUIRED',
  ONLY_CHARECTERS: 'MESSAGE.ONLY_CHARECTERS',
  VALID_PHONE: 'MESSAGE.VALID_PHONE',
  EMPTY_CONFIRM_PASSWORD: 'MESSAGE.EMPTY_CONFIRM_PASSWORD',
  EMPTY_CONFIRM_TRANSACTION_PASSWORD: 'MESSAGE.EMPTY_CONFIRM_TRANSACTION_PASSWORD',
  PASSWORD_LENGTH: 'MESSAGE.PASSWORD_LENGTH',
  PASSWORD_NOT_MATCH: 'MESSAGE.PASSWORD_NOT_MATCH',
  EMPTY_ACCESS_TOKEN: 'MESSAGE.EMPTY_ACCESS_TOKEN',
  EMPTY_OTP: 'MESSAGE.EMPTY_OTP',
  EMPTY_BANKNAME: 'MESSAGE.EMPTY_BANKNAME',
  SIMPLE_SIGNUP_SUCCESS: 'MESSAGE.SIMPLE_SIGNUP_SUCCESS',
  NUMBER_REQUIRED: 'MESSAGE.NUMBER_REQUIRED',
  DOB_REQUIRED: 'MESSAGE.DOB_REQUIRED',
  ADDRESS_REQUIRED: 'MESSAGE.ADDRESS_REQUIRED',
  EMPTY_COUNTRY: 'MESSAGE.EMPTY_COUNTRY',
  EMPTY_CITY: 'MESSAGE.EMPTY_CITY',
  SOME_THING_WRONG: 'MESSAGE.SOME_THING_WRONG',
  PROJECT_NAME_REQUIRED: 'MESSAGE.PROJECT_NAME_REQUIRED',
  PROJECT_DOMAIN_REQUIRED: 'MESSAGE.PROJECT_DOMAIN_REQUIRED',
  PROJECT_DOMAIN_INVALID: 'MESSAGE.PROJECT_DOMAIN_INVALID',
  FRONTEND_DOMAIN_INVALID: 'MESSAGE.FRONTEND_DOMAIN_INVALID',
  CLIENT_PUBLIC_KEY_REQUIRED: 'MESSAGE.CLIENT_PUBLIC_KEY_REQUIRED',
  BANNER_TITLE_REQUIRED: 'MESSAGE.BANNER_TITLE_REQUIRED',
  BANNER_TYPE_REQUIRED: 'MESSAGE.BANNER_TYPE_REQUIRED',
  BANNER_CATEGORY_REQUIRED: 'MESSAGE.BANNER_CATEGORY_REQUIRED',
  ZIP_CODE_REQUIRED: 'MESSAGE.ZIP_CODE_REQUIRED',
  AGENT_CODE_REQUIRED: 'MESSAGE.AGENT_CODE_REQUIRED',
  CAPTCHA_REQUIRED: 'MESSAGE.CAPTCHA_REQUIRED',
  MUST_BE_18: 'MESSAGE.MUST_BE_18',
  VALID_DATE: 'MESSAGE.VALID_DATE',
  CURRENCY_REQUIRED: 'MESSAGE.CURRENCY_REQUIRED',
  GENDER_REQUIRED: 'MESSAGE.GENDER_REQUIRED',
  NEWS_CHECK_REQUIRED: 'MESSAGE.NEWS_CHECK_REQUIRED',
  SMS_REQUIRED: 'MESSAGE.SMS_REQUIRED'
}

export const errorMessages = {
  betHistory: 'No bet history found',
  promotionPage: 'No promotions found',
  bonusPage: 'No bonuses found',
  bonusPageHistory: 'No history found',
  bonusPageYourBonuses: 'No bonuses found',
  withdrawalHistory: 'No withdawal history found',
  favoriteGames: 'No favorite games found',
  cms: 'No Cms Found'
}

export const kycAccordianData = [
  {
    id: 'level1',
    accordianLevel: 'level1',
    title: 'Level 1',
    content: ` The player should sign-up/register with e-mail, password, birthdate and a
  username.
  The player should verify the e-mail address before the player can deposit/withdrawal.
  Once the e-mail address is verified, the player will automatically obtain Level 1 KYC.
  Level 1 KYC is fixed to a maximum of 10.000 USD casino and 1000 USD bets on
  sportbook side.`,
    kycIndex: null,
    isFinal: false
  },
  {
    id: 'level2',
    accordianLevel: 'level2',
    title: 'Level 2',
    content: ` The player should select their country of residence in the KYC form.
  (list of banned countries will be given by us to automatically let the player know, that
  they cannot proceed with the KYC level 2.
  The player should upload there ID in form of a ID card or passport.
  Once ID card or passport is uploaded, an e-mail should be sent to the support team
  (email address not available yet) to manually check and verify the ID card or
  passport.
  Once support team has approved the ID / passport, the player has obtained Level 2
  KYC and will be reflected on their profile.
  The player is now able to place 50.000 USD and 10.000 USD bets on sportsbook.`,
    kycIndex: 0,
    isFinal: false
  },
  {
    id: 'level3',
    accordianLevel: 'level3',
    title: 'Level 3',
    content: `  The player should provide their proof of address and fill in the KYC
  form with current address. The player should upload their proof of address in form
  of a utility bill or something similar that will reflect their address.
  Once their proof of address is uploaded, an e-mail should be sent to the support
  team (email address not available yet) to manually check and verify the their proof
  of address. Once support team has approved the their proof of address, the player
  has obtained Level 3 KYC and will be reflected on their profile.
  The player is now able to place 150.000 USD and 25.000 USD bets on sportsbook.`,
    kycIndex: 1,
    isFinal: false
  },
  {
    id: 'level4',
    accordianLevel: 'level4',
    title: 'Level 4',
    content: ` The player should provide their proof of income and upload their salary
  slip or bankstatment. Once their proof of income is uploaded, an e-mail should be
  sent to the support team (email address not available yet) to manually check and
  verify the their proof of income. Once support team has approved the their proof of
  income, the player has obtained Level 4 KYC and will be reflected on their profile.
  The player is now able to place 1.000.000 USD and 1.000.000 USD bets on
  sportsbook.`,
    kycIndex: 2,
    isFinal: false
  },
  {
    id: 'level5',
    accordianLevel: 'level5',
    title: 'Level 5',
    content: `The player can only obtain this level given by super admins. This will
  give players unrestricted bet amount to casino and sportbook. The player should be
  able to see a VIP status in their profile.`,
    kycIndex: null,
    isFinal: true
  }
]

export const kycStatus = {
  0: 'pending',
  1: 'completed',
  2: 'rejected',
  3: 'cancelled',
  4: 're-Requested'
}

export const userBonusStatus = {
  PENDING: 'pending',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  CLAIMING: 'claiming',
  CLAIMED: 'claimed',
  IN_PROCESS: 'in_process',
}

export const userBonusStatusValue = {
  pending: 'Pending',
  active: 'Active',
  cancelled: 'Cancelled',
  expired: 'Expired',
  claiming: 'Claiming',
  claimed: 'Claimed',
  in_process: 'In Process',
}

export const BonusTypes = {
  DEPOSIT: 'deposit',
  FREESPIN: 'freespin',
  CASHBACK: 'cashback'
}


export const monthValues = [
  'Select Month',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
