import { useTheme } from "@emotion/react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grow from "@mui/material/Grow";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import {
  ChecronDown,
  WalletIcon,
  WalletSettingsIcon,
} from "assets/icons/index";
import { CustomMainButton } from "components/layout/Common/StyledButton/CustomButton";
import { getEmailCookie } from "helpers/cookie.helpers";
import { decryptData, encrypt } from "helpers/obfus";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setFilteredCurrencyData,
  setShowWalletPopup,
  setUpdateCurrencyCodeDataLoader,
} from "redux-thunk/redux/slices/wallet.slice";
import { currencyCodeUpdateRequest } from "redux-thunk/thunk/user.thunk";
import { formatPrice } from "utils/numberFormatter";
import { currencyIcon } from "../../../../../utils/currencyIcon";
import { UserCoinWrapper } from "../../style";
import MenuItemComponent from "./component/MenuItemComponent";
import SymbolComponents from "./component/SymbolComponents";
import { useStyles } from "./style";
import WalletSettings from "./WalletSettings";

const UserCoin = memo(({ daken }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user?.user?.getUser);
  const { filteredCurrencData, updateCurrencyCodeDataLoader } = useSelector(
    (state) => state.wallet
  );
  const token = daken;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState({});
  const [openWalletSettingsPopup, setOpenWalletSettingsPopup] = useState(false);
  const [isWalletButtonClicked, setIsWalletButttonClicked] = useState(true);
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  const [changeCurrencySetting, setChangeCurrencySetting] = useState({
    hideZeroBalance: false,
    displayCryptoInFiat: false,
    currentCurrencyCode: "",
    faitCurrencyCode: "USD",
  });

  const [dataSocket, setDataSocket] = useState(null);
  const [connectedSocket, setConnectedSocket] = useState(false);
  const [error, setError] = useState(null);

  const isGamePlay = location.pathname.includes("/play-game/");
  const isCurrencyDisabled =
    location.pathname?.includes("/casino/play-game") || isGamePlay;

  const handleToggle = () => {
    if (isCurrencyDisabled) return;

    setOpen(!open);
    dispatch(setFilteredCurrencyData(renderCurrencyArr()));
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const walletHandleOpen = () => {
    setIsWalletButttonClicked(false);
    dispatch(setShowWalletPopup(true));
  };

  const handleMenuItemClick = (...args) => {
    const [currency, index] = args;
    setDataSocket((prevData) => ({
      ...prevData,
      currencyCode: currency.currentCurrencyCode,
    }));

    dispatch(setUpdateCurrencyCodeDataLoader(true));
    setIsWalletButttonClicked(true);
    setSelectedIndex(index);
    setOpen(false);
    const reqBody = {
      hideZero: currency?.hideZeroBalance,
      displayCryptoInFlat: currency?.displayCryptoInFiat,
      currencyCode:
        currency?.currentCurrencyCode || userData?.currencyCode || "",
      faitCurrencyCode:
        currency?.faitCurrencyCode ||
        userData?.other?.faitCurrencyCode ||
        "USD",
    };

    dispatch(currencyCodeUpdateRequest({ values: reqBody }));
  };

  const arrForRenderList = (arr) => {
    const mappedArrData = arr?.map((currencydata) => {
      const result = {
        ...currencydata?.currency,
        amount: currencydata.amount,
        nonCashAmount: currencydata.nonCashAmount,
      };
      return result;
    });
    return mappedArrData;
  };

  const changeDropDownValue = (dropDownData) => {
    const filterCurrencyCodeData = dropDownData?.filter(
      (currencyCodeDetails, index) =>
        currencyCodeDetails?.currency?.code === dataSocket?.currencyCode
    );
    setSelectedCurrencyCode(...arrForRenderList(filterCurrencyCodeData));
    dropDownData?.map((currencyCode, ind) => {
      if (currencyCode?.code === userData?.currencyCode) {
        setSelectedIndex(ind);
      }
      return [];
    });
    dispatch(setUpdateCurrencyCodeDataLoader(false));
  };

  const renderCurrencyArr = useCallback(() => {
    changeDropDownValue(dataSocket?.userWallets || []);

    if (userData?.other?.hideZero) {
      const filterZeroAmountCurrency =
        dataSocket?.userWallets &&
        dataSocket?.userWallets?.filter(
          (currencyCodeDetails, index) => currencyCodeDetails?.amount !== 0
        );
      return arrForRenderList(filterZeroAmountCurrency);
    }
    return arrForRenderList(dataSocket?.userWallets);
  }, [dataSocket, userData]);

  const filterData = (data) => {
    if (!data) return [];

    // Apply hideZeroBalance filter
    let filteredData = changeCurrencySetting.hideZeroBalance
      ? data.filter((currency) => currency.amount !== 0)
      : data;

    // Ensure searchTerm is a string
    const search = searchTerm;

    // Apply search filter
    if (search) {
      filteredData = filteredData.filter(
        (currency) =>
          currency.code.toLowerCase().includes(search) ||
          currency.name.toLowerCase().includes(search)
      );
    }

    // Apply displayCryptoInFiat filter
    if (changeCurrencySetting.displayCryptoInFiat) {
      return filteredData.map((currency) => {
        if (currency.code !== changeCurrencySetting.faitCurrencyCode) {
          // Convert amount to fiat currency here if needed
          // For simplicity, assuming conversion is not applied directly in this example
          return {
            ...currency,
            amount: currency.amount, // Replace with actual conversion logic
          };
        }
        return currency;
      });
    }
    return filteredData;
  };

  const handleSearch = (value) => {
    const filteredData = filterData(renderCurrencyArr());
    dispatch(setFilteredCurrencyData(filteredData));
  };

  useEffect(() => {
    dispatch(setFilteredCurrencyData(filterData(renderCurrencyArr())));
  }, [renderCurrencyArr, changeCurrencySetting, searchTerm, dispatch]);

  useEffect(() => {
    // document.body.style.overflow = !open ? 'auto' : 'hidden'
    setOpenBackdrop(open);
  }, [open]);

  useEffect(() => {
    setChangeCurrencySetting({
      ...changeCurrencySetting,
      hideZeroBalance: userData?.other?.hideZero,
      displayCryptoInFiat: userData?.other?.displayCryptoInFlat,
      currencyCode: userData?.currencyCode,
      faitCurrencyCode: userData?.other?.faitCurrencyCode,
    });
  }, [userData]);

  useEffect(() => {
    if (getEmailCookie()) {
      const ws = new WebSocket("wss://socwal.deuces.com");
      ws.onopen = () => {
        setConnectedSocket(true);
        const message = {
          type: "wallet",
          email: getEmailCookie(),
        };

        ws.send(encrypt(JSON.stringify(message)));
      };
      ws.onclose = () => {
        setConnectedSocket(false);
      };
      ws.onmessage = async (event) => {
        try {
          const decryptedData = await decryptData(event.data);
          const data = JSON.parse(decryptedData);

          setDataSocket(data);
        } catch (err) {}
      };
      ws.onerror = (error) => {
        setError(error);
      };
      return () => {
        ws.close();
      };
    }
  }, [daken]);

  const renderAmount = () => {
    if (!selectedCurrencyCode || !dataSocket) return 0;

    const { code, amount } = selectedCurrencyCode;
    const { other, convertedRate } = dataSocket;

    let result = formatPrice(amount, changeCurrencySetting.displayCryptoInFiat);

    if (isCurrencyDisabled) {
      result = "In Play";
    } else if (other?.displayCryptoInFlat && code !== "IDR") {
      result = formatPrice(
        Number(amount) / convertedRate[code],
        changeCurrencySetting.displayCryptoInFiat
      );
    }

    return result;
  };

  return (
    token && (
      <>
        <UserCoinWrapper theme={theme} className="header-user-coin">
          {updateCurrencyCodeDataLoader && isWalletButtonClicked ? (
            <Box className="loderBox">
              <Stack
                sx={{ color: "grey.500", mr: 1 }}
                spacing={2}
                direction="row"
              >
                <CircularProgress color="success" />
              </Stack>
            </Box>
          ) : (
            <div
              disabled={isCurrencyDisabled}
              className={`dropdown-btn wallet-btn ${
                isCurrencyDisabled ? "currency-disabled" : ""
              }`}
              onClick={handleToggle}
            >
              <Box ref={anchorRef}>
                <Box className="btc-coin-box">
                  <ButtonGroup variant="contained" aria-label="split button">
                    {currencyIcon[selectedCurrencyCode?.code]} &nbsp;
                    <Button>
                      <Typography className="currency-text">
                        {" "}
                        {selectedCurrencyCode?.code}
                      </Typography>
                    </Button>
                    <Button
                      size="small"
                      aria-controls={open ? "split-button-menu" : undefined}
                      aria-expanded={open ? true : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                    >
                      <ChecronDown />
                    </Button>
                  </ButtonGroup>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Popper
                      className="theme-dropdown"
                      sx={{ zIndex: 9999 }}
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper sx={{ mt: 2.2, width: 240 }}>
                            {/* <SearchComponent
                              onSearchData={handleSearch} // Update search term and filter data
                              textSearch={(value) => {
                                setSearchTerm(value || ""); // Ensure value is a string
                              }}
                            /> */}
                            <MenuList>
                              {filteredCurrencData?.map((currency, index) => (
                                <MenuItem
                                  className="coin-list-item"
                                  key={currency?.currencyId}
                                  selected={index === selectedIndex}
                                  disabled={currency?.code === "USD"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMenuItemClick(
                                      { currentCurrencyCode: currency?.code },
                                      index
                                    );
                                  }}
                                >
                                  <MenuItemComponent
                                    option={currency}
                                    additionalInfo={dataSocket}
                                  />
                                </MenuItem>
                              ))}
                              <Button
                                onClick={() => setOpenWalletSettingsPopup(true)}
                                className="wallet-btn"
                              >
                                <Typography>
                                  <WalletSettingsIcon /> &nbsp;{" "}
                                  {t("walletSettings")}
                                </Typography>
                              </Button>
                            </MenuList>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </ClickAwayListener>
                </Box>
                {dataSocket && selectedCurrencyCode && (
                  <Box>
                    <Typography className="btc-coins">
                      {isCurrencyDisabled ? (
                        ""
                      ) : (
                        <SymbolComponents option={selectedCurrencyCode} />
                      )}
                      &nbsp;
                      {renderAmount()}
                    </Typography>
                  </Box>
                )}
              </Box>
            </div>
          )}
          <Backdrop className={classes.backdrop} open={openBackdrop} />
          <CustomMainButton
            type="button"
            onClick={walletHandleOpen}
            className={`btn-head-wallet ${
              isCurrencyDisabled ? "currency-disabled" : ""
            }`}
            disabled={isCurrencyDisabled}
            aria-label="WalletIcon"
          >
            <WalletIcon />
          </CustomMainButton>
        </UserCoinWrapper>
        <WalletSettings
          openWalletSettingsPopup={openWalletSettingsPopup}
          setOpenWalletSettingsPopup={setOpenWalletSettingsPopup}
          changeCurrencySetting={changeCurrencySetting}
          setChangeCurrencySetting={setChangeCurrencySetting}
          handleSwitchItemClick={handleMenuItemClick}
        />
      </>
    )
  );
});

export default UserCoin;
