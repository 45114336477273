import { LOADER_HANDLER_TYPES } from 'constants/index'
import React, { useState, memo } from 'react'
import { useSelector } from 'react-redux'
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import QRCodeGenerator from 'components/layout/QRCodeGenerator/index'
import { useTranslation } from 'react-i18next'
import CopyFieldComponent from '../../../components/layout/CopyField/CopyFiledComponent'
import { minimunConfirm } from '../../../utils/minimumConfirm'

const CryptoComponent = (props) => {
  const { currencyLimit, isGetDepositAddApiCall, selectedCurrency } = props

  const { [LOADER_HANDLER_TYPES.content]: loading } = useSelector(state => state.loader)
  const { depositData, depositCurrency } = useSelector(state => state.wallet)
  const { t } = useTranslation()
  const [isTextCopied, setIsTextCopied] = useState({
    isCopy: false,
    message: ''
  })
  const handleCopyClick = (copyData) => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(copyData)
      const message = t('copiedMessage', { title: t('depositAddress') })
      setIsTextCopied({ isCopy: true, message })
    }
    setTimeout(() => {
      setIsTextCopied({ isCopy: false, message: '' })
    }, 10000)
  }
  return (
    <>{
        loading && isGetDepositAddApiCall && selectedCurrency?.type === 0
          ? (
            <Box className='loderBox'>
              <Stack sx={{ color: 'grey.500' }} spacing={2} direction='row'>
                <CircularProgress color='success' />
              </Stack>
            </Box>
            )
          : (
              depositData?.address && (
                <>
                  <CopyFieldComponent
                    value={depositData?.address}
                    title={t(`Your ${depositCurrency} deposit address`)}
                    handleCopyClick={() => handleCopyClick(depositData?.address)}
                    name='depositAddress'
                    copyTitleMessage={t('copyTitleMessage', { title: t('depositAddress') })}
                    setIsTextCopied={setIsTextCopied}
                    isTextCopied={isTextCopied}
                  />
                  <Grid item xs={12} textAlign='center'>
                    <Box sx={{ mb: 3 }} className='QRCodeGenerator'>
                      <QRCodeGenerator value={depositData?.address} />
                    </Box>
                    <Typography
                      component='p'
                      textAlign='center'
                      className='wallet-bottom-text'
                    >
                      {/* {t('minDepositAmount', { min: `${currencyLimit?.value?.[depositCurrency]} ${depositCurrency}` })} */}
                    </Typography>
                    <Typography
                      component='p'
                      textAlign='center'
                      className='wallet-bottom-text'
                    >
                      {t(`Only send ${depositCurrency} to this address, ${minimunConfirm[depositCurrency]} confirmations required`)}
                    </Typography>
                  </Grid>
                </>
              )
            )
      }
    </>
  )
}

export default memo(CryptoComponent)
