import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSiteinfo } from 'redux-thunk/thunk/gameSetting'
import { Box, Button, useTheme, Menu, MenuItem, IconButton, Popover } from '@mui/material'
import { FooterMenuWrapper } from './style'
import { BellIcon, DiceMobile } from 'assets/icons/index'
import { setShowSideNav } from 'redux-thunk/redux/slices/settings.slice'
import { setChangeHamburgerIconClass } from 'redux-thunk/redux/slices/user.slice'
import { setShowChat } from 'redux-thunk/redux/slices/settings.slice'
import { setShowWalletPopup } from 'redux-thunk/redux/slices/wallet.slice'
import { GiHamburgerMenu } from "react-icons/gi";
import { FaUser } from "react-icons/fa";
import { FaWallet } from "react-icons/fa6";
import { IoDice } from "react-icons/io5";
import { getAuthToken } from 'helpers/cookie.helpers'
import { setGameCategoryId } from "redux-thunk/redux/slices/games.slice";
import {
  getCasinoCategoryGame,
  getCasinoGamesCategories,
} from "redux-thunk/thunk/game.thunk";
import { setTab } from "redux-thunk/redux/slices/lobby.slice";
import { ROUTE_PATHS } from 'constants/index'
import { useTranslation } from 'react-i18next'
import { setUserBonus } from 'redux-thunk/redux/slices/bonus.slice'
import { userLogout } from 'redux-thunk/thunk/auth.thunk'
import MyAccountSVG from 'assets/userSpecific/my-account.svg'
import MyWalletSVG from 'assets/userSpecific/my-wallet.svg'
import TrannsactionSVG from 'assets/userSpecific/transaction.svg'
import LogoutSVG from 'assets/userSpecific/logout.svg'
import MyBonusesSVG from 'assets/userSpecific/bonuses.svg'

const FooterMenu = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const token = !!getAuthToken()
  const { t } = useTranslation()

  const { cmsData } = useSelector((state) => state?.gameSetting)
  const cmsDetails = cmsData?.cmsDetails || []

  const [openSideBar, setOpen] = useState(false)
  const { showChat } = useSelector((state) => state?.settings)

  useEffect(() => {
    dispatch(getAllSiteinfo({}))
  }, [dispatch])

  const handleHamburgerClick = () => {
    dispatch(setShowSideNav(openSideBar))
    dispatch(setChangeHamburgerIconClass('sidebar-open'))
  }

  useEffect(() => {
    handleHamburgerClick()
    return () => dispatch(setChangeHamburgerIconClass('sidebar-close'))
  }, [openSideBar, dispatch])

  const getAllCategoryGames = () => {
    dispatch(setGameCategoryId({ id: null, subCategory: null }));
    dispatch(getCasinoCategoryGame({}));
    history.push("/casino");
  };

  const tabChangeHandler = (tabName) => {
    history.push(`/${tabName}`);
    dispatch(setTab(tabName));
  };

  const [anchorElUser, setAnchorElUser] = useState(null)
  const [isLogOutClick, setIsLogOutClick] = useState(false)
  const userData = useSelector((state) => state?.user?.user?.getUser)
  const { logoutLoader } = useSelector((state) => state?.user)

  const userSpecificDetails = {
    myAccount: 'myAccount',
    myWallet: 'myWallet',
    myBonuses: 'myBonuses',
    userLogout: 'userLogout',
    transactions: 'transactions',
  }

  const settings = [
    {
      name: userSpecificDetails?.myAccount,
      title: t('myAccount'),
      srcURL: MyAccountSVG
    },
    {
      name: userSpecificDetails?.myWallet,
      title: t('wallet'),
      srcURL: MyWalletSVG
    },
    {
      name: userSpecificDetails?.myBonuses,
      title: t('myBonuses'),
      srcURL: MyBonusesSVG
    },
    {
      name: userSpecificDetails?.transactions,
      title: t('accountMenuTransactions'),
      srcURL: TrannsactionSVG
    },
    {
      name: userSpecificDetails?.userLogout,
      title: t('accountMenuLogout'),
      srcURL: LogoutSVG,
      isDisabled: logoutLoader && isLogOutClick
    }
  ]

  const handleOpenUserMenu = (event) => {
    if (userData?.username) { setAnchorElUser(event.currentTarget) }
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  useEffect(() => {
    if (!token) {
      handleCloseUserMenu()
    }

    if (token) {
      localStorage.removeItem('affiliateCode')
    }
  }, [token])

  const handleMenuClick = (name) => {
    if (name === userSpecificDetails?.myAccount) {
      handleCloseUserMenu()
      history.push(ROUTE_PATHS?.MY_ACCOUNT)
    } else if (name === userSpecificDetails?.myWallet) {
      dispatch(setShowWalletPopup(true))
    } else if (name === userSpecificDetails?.userLogout) {
      setIsLogOutClick(true)
      dispatch(userLogout({ history }))
      localStorage.clear()
      dispatch(setUserBonus([]))
    } else if (name === userSpecificDetails?.transactions) {
      handleCloseUserMenu()
      history.push(ROUTE_PATHS.TRANSACTION)
    } else if (name === userSpecificDetails?.myBonuses) {
      handleCloseUserMenu()
      history.push(ROUTE_PATHS.MY_BONUSES)
    }
  }

  return (
    <FooterMenuWrapper theme={theme}>
      <Box className='footer-menu-list' container spacing={2}>
        <Button onClick={() => setOpen((prev) => !prev)}>
          <GiHamburgerMenu/>
          <span>Menu</span>
        </Button>
        {/* <Button onClick={() => dispatch(setShowChat(!showChat))}>
          <BellIcon />
          <span>Chat</span>
        </Button> */}
        <Button
          onClick={() => {
            tabChangeHandler("casino");
            getAllCategoryGames();
          }}
        >
          <IoDice/>
          <span>Casino</span>
        </Button>
        {token && <Button
          onClick={() => handleMenuClick(userSpecificDetails.myWallet)}
        >
          <FaWallet/>
          <span>Wallet</span>
        </Button>}
        {token &&
         <Button
         onClick={handleOpenUserMenu}
        >
          <FaUser/>
          <span>Account</span>
        </Button>
        }
      </Box>

      <Popover
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting.name}
            disabled={setting.isDisabled}
            onClick={() => handleMenuClick(setting.name)}
            className='img-icon'
          >
            <img style={{width: '20px', height: '20px', marginRight: '8px'}} src={setting.srcURL} alt={setting.title} />
            {setting.title}
          </MenuItem>
        ))}
      </Popover>
    </FooterMenuWrapper>
  )
}
export default FooterMenu
