import { createSlice } from '@reduxjs/toolkit'
import { getLocalWalletSetting, setLocalWalletSetting } from 'helpers/localstorage.helpers'
import { userLogout, verifyEmail } from 'redux-thunk/thunk/auth.thunk'
import { getAllSiteinfo } from 'redux-thunk/thunk/gameSetting'
import {
  fetchUserInformation,
  getActiveJoiningBonus,
  getActiveReferralBonus,
  getUserPlacedBets,
  getWithdrawRequest,
  updateProfilePic,
  getDocumentsLabelRequest,
  getDocumentsInfoRequest,
  updateDocVerificationRequest,
  getReferredUsersData
} from 'redux-thunk/thunk/user.thunk'

const initialState = {
  user: null,
  selectedWallet: null,
  redirectToAfterLogin: '',
  transactions: {
    rows: [],
    count: 0
  },
  usersTransactions: {
    rows: [],
    count: 0
  },
  usersTransactionDataLoader: false,
  topBetTransactions: {
    rows: [],
    count: 0
  },
  withdrawRequestData: null,
  qrCodeURl: null,
  showLoginOtp: false,
  siteInfo: null,
  roundData: null,
  showRoundHistoryModel: false,
  hideZeroCurrency: getLocalWalletSetting()?.hideZeroCurrency || false,
  displayFiatCurrency: getLocalWalletSetting()?.displayFiatCurrency || false,
  selectedFiat: getLocalWalletSetting()?.selectedFiat || 'USD',
  activeJoiningBonus: null,
  activeReferralBonus: null,
  emailVerificationError: null,
  loyaltyData: null,
  chat: [],
  verificationLabels: null,
  verificationUserDocument: null,
  verificationError: null,
  hamburgerIconClass: '',
  affiliateData: null,
  affiliateManagementData: null,
  profileUploadingLoader: false,
  referredUsersData: [],
  referredUsersDataLoader: false,
  kycDocumentLabel: [],
  kycDocuments: [],
  logoutLoader: false,
  profilePicUploaded: false

}
const {
  actions: {
    setUserData,
    setQrcodeUrl,
    setSelectedWallet,
    setRedirectToAfterLogin,
    setAfterResetPassword,
    setTransactions,
    showLoginOtpHandle,
    setSiteInfo,
    setRoundData,
    setWalletSetting,
    setTopBetTransactions,
    setChat,
    setUsersTransactions,
    setLoyaltyDetails,
    setChangeHamburgerIconClass,
    setAffiliateData,
    setAffiliateManagementData,
    setProfileUploadingLoader,
    setReferredUsersDataLoader,
    setKycDocuments,
    setKycDocumentLabel,
    setUsersTransactionDataLoader,
    setProfilePicUploaded,
    setEmailVerificationError
  },
  reducer
} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setChat: (state, action) => {
      if (action.payload?.chatData) {
        const { chatData } = action.payload
        return {
          ...state,
          chat: [...state.chat, ...chatData]
        }
      }
      return {
        ...state,
        chat: [...state.chat, action.payload]
      }
    },
    setUserData: (state, action) => ({
      ...state,
      user: action.payload
    }),
    setQrcodeUrl: (state, action) => ({
      ...state,
      qrCodeURl: action.payload
    }),
    setSelectedWallet: (state, action) => ({
      ...state,
      selectedWallet: action.payload
    }),
    setRedirectToAfterLogin: (state, action) => ({
      ...state,
      redirectToAfterLogin: action.payload
    }),
    setAfterResetPassword: (state, action) => ({
      ...state,
      afterResetPassword: action.payload
    }),
    showLoginOtpHandle: (state, action) => ({
      ...state,
      showLoginOtp: action.payload
    }),
    setTransactions: (state, action) => ({
      ...state,
      transactions: action.payload
    }),
    setUsersTransactions: (state, action) => ({
      ...state,
      usersTransactions: action.payload,
      usersTransactionDataLoader: false
    }),
    setUsersTransactionDataLoader: (state, action) => ({
      ...state,
      usersTransactionDataLoader: action.payload
    }),
    setLoyaltyDetails: (state, action) => ({
      ...state,
      loyaltyData: action.payload
    }),

    setTopBetTransactions: (state, action) => ({
      ...state,
      topBetTransactions: action.payload
    }),
    setSiteInfo: (state, action) => ({
      ...state,
      siteInfo: action.payload
    }),
    setRoundData: (state, action) => ({
      ...state,
      roundData: action.payload,
      showRoundHistoryModel: !!action.payload
    }),
    setAffiliateData: (state, action) => ({
      ...state,
      affiliateData: action.payload
    }),
    setAffiliateManagementData: (state, action) => ({
      ...state,
      affiliateManagementData: action.payload
    }),
    setWalletSetting: (state, action) => {
      setLocalWalletSetting({
        hideZeroCurrency: action.payload.hideZero,
        displayFiatCurrency: action.payload.displayFiat,
        selectedFiat: action.payload.selectedFiatCurrency
      })
      return ({
        ...state,
        hideZeroCurrency: action.payload.hideZero,
        displayFiatCurrency: action.payload.displayFiat,
        selectedFiat: action.payload.selectedFiatCurrency
      })
    },
    setChangeHamburgerIconClass: (state, action) => ({
      ...state,
      hamburgerIconClass: action?.payload
    }),
    setProfileUploadingLoader: (state, action) => ({
      ...state,
      profileUploadingLoader: action?.payload
    }),
    setReferredUsersDataLoader: (state, action) => ({
      ...state,
      referredUsersDataLoader: action?.payload
    }),
    setKycDocuments: (state, action) => ({
      ...state,
      kycDocuments: action?.payload
    }),
    setKycDocumentLabel: (state, action) => ({
      ...state,
      kycDocumentLabel: action?.payload
    }),
    setProfilePicUploaded: (state, action) => ({
      ...state,
      profilePicUploaded: false
    }),
    setEmailVerificationError: (state, action) => ({
      ...state,
      emailVerificationError: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          user: action.payload,
          selectedWallet: action.payload?.wallets?.find(ele => ele.primary) || action?.payload?.userWallet?.amount
        }
      })
      .addCase(getAllSiteinfo.fulfilled, (state, action) => {
        return {
          ...state,
          siteInfo: action.payload?.cms
        }
      })
      .addCase(updateProfilePic.fulfilled, (state, action) => {
        return {
          ...state,
          profileUploadingLoader: false,
          profilePicUploaded: action.payload?.success,
          user: { ...state.user, getUser: { ...state.user.getUser, profileImage: action.payload?.key } }
        }
      })
      .addCase(updateProfilePic.rejected, (state, action) => {
        return {
          ...state,
          profilePicUploaded: false,
          profileUploadingLoader: false
        }
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...state,
          logoutLoader: false
        }
      })
      .addCase(userLogout.rejected, (state, action) => {
        return {
          ...state,
          logoutLoader: false
        }
      })
      .addCase(userLogout.pending, (state, action) => {
        return {
          ...state,
          logoutLoader: true
        }
      })
      .addCase(getUserPlacedBets.fulfilled, (state, action) => {
        return {
          ...state,
          transactions: action.payload
        }
      })
      .addCase(getWithdrawRequest.fulfilled, (state, action) => {
        return {
          ...state,
          withdrawRequestData: action.payload
        }
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        return {
          ...state,
          emailVerificationError: action.payload
        }
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        return {
          ...state,
          emailVerificationError: action.payload
        }
      })
      .addCase(getActiveJoiningBonus.fulfilled, (state, action) => {
        return {
          ...state,
          activeJoiningBonus: action.payload
        }
      })
      .addCase(getActiveReferralBonus.fulfilled, (state, action) => {
        return {
          ...state,
          activeReferralBonus: action.payload
        }
      })
      .addCase(getDocumentsLabelRequest.fulfilled, (state, action) => {
        return {
          ...state,
          verificationLabels: action.payload
        }
      })
      .addCase(getDocumentsLabelRequest.rejected, (state, action) => {
        return {
          ...state,
          verificationError: action.payload
        }
      })
      .addCase(getDocumentsInfoRequest.fulfilled, (state, action) => {
        return {
          ...state,
          verificationUserDocument: action.payload
        }
      })
      .addCase(getDocumentsInfoRequest.rejected, (state, action) => {
        return {
          ...state,
          verificationError: action.payload
        }
      })
      .addCase(updateDocVerificationRequest.fulfilled, (state, action) => {
        return {
          ...state,
          verificationUserDocument: action.payload
        }
      })
      .addCase(updateDocVerificationRequest.rejected, (state, action) => {
        return {
          ...state,
          verificationError: action.payload
        }
      })
      .addCase(getReferredUsersData.fulfilled, (state, action) => {
        return {
          ...state,
          referredUsersDataLoader: false,
          referredUsersData: action.payload
        }
      })
      .addCase(getReferredUsersData.rejected, (state, action) => {
        return {
          ...state,
          referredUsersDataLoader: true,
          referredUsersData: action.payload
        }
      })
  }
})

export default reducer
export {
  setUserData,
  setSelectedWallet,
  setRedirectToAfterLogin,
  setAfterResetPassword,
  setTransactions,
  setQrcodeUrl,
  showLoginOtpHandle,
  setSiteInfo,
  setRoundData,
  setWalletSetting,
  setTopBetTransactions,
  setChat,
  setUsersTransactions,
  setLoyaltyDetails,
  setReferredUsersDataLoader,
  setChangeHamburgerIconClass,
  setAffiliateData,
  setProfileUploadingLoader,
  setKycDocuments,
  setKycDocumentLabel,
  setAffiliateManagementData,
  setUsersTransactionDataLoader,
  setProfilePicUploaded,
  setEmailVerificationError
}
