// CryptoComponent.js
import React from 'react'
import { Formik, Form } from 'formik'
import { Box, Grid, InputLabel, Typography, List, ListItem, ListItemText } from '@mui/material'
import { CustomTextField } from 'components/layout/Common/StyledForm/style'
import { CustomMainButton } from 'components/layout/Common/StyledButton/CustomButton'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import { walletWithdrawCryptoSchema } from '../schema'
import CircularProgressBar from 'components/layout/ProgressBar/CircularProgressBar'
import AlertBox from 'components/Popup/AlertBox'
import { useTranslation } from 'react-i18next'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import Loader from 'components/ui-kit/Loader/index'

const CryptoComponent = ({ MIN_MAX_WITHDRAW_AMOUNT, depositData, handleWithdrawRequest, handleMaxValue, alertBoxopen, setAlertBoxOpen, loading, withdrawalFeeLoader, error, walletCurrency, withdrawalFee, handleAlertBoxClose }) => {
  const { t } = useTranslation()

  return (
    <> {(walletCurrency && walletCurrency !== 'default') &&
      <Box sx={{ ml: 2 }}>
        <Formik
          enableReinitialize
          initialValues={{ address: '', amount: '' }}
          validationSchema={walletWithdrawCryptoSchema(t, { MIN_MAX_WITHDRAW_AMOUNT, address: depositData?.address })}
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={handleWithdrawRequest}
        >
          {({ values, handleChange, handleSubmit, handleBlur }) => (
            <Form autoComplete='off'>
              <Grid container spacing={2} className='login-form-group'>
                <Grid item xs={12}>
                  <InputLabel className='input-label'>
                    {t('Address')}
                  </InputLabel>
                  <CustomTextField
                    type='text'
                    className='form-control shadow-none'
                    placeholder={t('pasteYourCurrencyAddressHere', { currency: walletCurrency })}
                    name='address'
                    id='address'
                    value={values.address}
                    onChange={(e) => {
                      alertBoxopen && setAlertBoxOpen(false)
                      handleChange(e)
                    }}
                  />
                  <ErrorMessage name='address' component='div' className='color-red text-danger error-msg login-input ms-2 d-flex' />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className='input-label'>
                    {t(`Amount (Min. ${MIN_MAX_WITHDRAW_AMOUNT} ${walletCurrency})`)}
                    <Typography className='required-indicator'>*</Typography>
                  </InputLabel>
                  <div className="input-with-button">
                    <CustomTextField
                      type='number'
                      style={{ marginBottom: '0px' }}
                      className='form-control shadow-none'
                      placeholder='Enter Amount'
                      name='amount'
                      value={values.amount}
                      onChange={(e) => {
                        alertBoxopen && setAlertBoxOpen(false)
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                    />
                    <button onClick={() => values.amount = handleMaxValue()}>Max</button>
                  </div>
                  <ErrorMessage name='amount' component='div' className='color-red text-danger error-msg login-input ms-2 d-flex' />
                </Grid>
                <Box className='address-submit-box'>
                  <List className='wallet-list'>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary={t(`Please ensure that you do NOT input BEP2, BEP20 (BSC) address as a ${walletCurrency} withdraw address wallets`)}
                      />
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary='Please do NOT withdraw as cross-chain transfer'
                      />
                    </ListItem>
                  </List>

                  <CustomMainButton
                    type='submit'
                    fullWidth
                    disabled={loading}
                    sx={{ mb: 4 }}
                  >
                    {loading ? <Loader variant={LOADER_HANDLER_TYPES.submit} /> : <span>{t('withdrawWithdrawBtn')}</span>}
                  </CustomMainButton>
                  {
                    withdrawalFeeLoader
                      ? <CircularProgressBar />
                      : !isNaN(withdrawalFee) && (
                        <Typography
                          component='p'
                          textAlign='center'
                          className='wallet-bottom-text'
                        >
                          {t('Withdrawal Fee')}: <Box component='span' className='text-white'>{t(`${withdrawalFee} ${walletCurrency}`)}</Box>
                        </Typography>
                      )
                  }

                  {alertBoxopen &&
                    (
                      <Grid item xs={12} mt={2}>
                        <Box className='custom-alert-box'>
                          <AlertBox
                            open={alertBoxopen}
                            handleClose={handleAlertBoxClose}
                            messageType={error?.messageType}
                            message={error?.message}
                          />
                        </Box>
                      </Grid>
                    )}
                </Box>
              </Grid>
            </Form>)}
        </Formik>
      </Box>}
    </>
  )
}

export default CryptoComponent
