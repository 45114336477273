import { TextField, styled } from '@mui/material'

// export const CustomFormWrapper = styled(Box)(({ theme }) => {
//     return {
//     };
// });
export const CustomTextField = styled(TextField)(({ theme }) => {
  return {
    width: '100%',
    borderRadius: theme.spacing(1),
    background: '#0d1b32',
    marginBottom: '5px',
    '& .MuiInputBase-root': {
      maxHeight: theme.spacing(7),
      borderRadius: theme.spacing(1.25),
      '& .MuiInputBase-input': {
        color: theme.palette.primary.contrastText,
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightRegular,
        padding: theme.spacing(2.25, 2),
        // Autofill styling to ensure consistent background and no border
        '&:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 1000px #0d1b32 inset !important`, // Match the background color
          WebkitTextFillColor: `${theme.palette.primary.contrastText} !important`, // Match the text color
          borderRadius: theme.spacing(1.25), // Ensure border radius is consistent
          border: 'none', // Remove border
          transition: 'background-color 5000s ease-in-out 0s', // Prevent background change
        },
        '&:-internal-autofill-selected': {
          WebkitBoxShadow: '0 0 0 1000px #0d1b32 inset', // Match the background color
          WebkitTextFillColor: `${theme.palette.primary.contrastText} !important`, // Text color
          borderRadius: theme.spacing(1.25), // Ensure border radius is maintained
          border: 'none', // Remove border
          transition: 'background-color 5000s ease-in-out 0s', // Prevent background change
        },
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.contrastText,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.border.transparent,
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.green,
        },
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.transparent,
        },
      },
      '&.Mui-focused, &.Mui-focused:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.green,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.contrastText,
        cursor: 'no-drop',
      },
    },
    '.MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.primary.contrastText,
    },
  };
});
