import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material/index";
import { CustomMainButton } from "components/layout/Common/StyledButton/CustomButton";
import { CustomTextField } from "components/layout/Common/StyledForm/style";
import ErrorMessage from "components/layout/ErrorMessage/index";
import AlertPopup from "components/Popup/AlertPopup";
import Loader from "components/ui-kit/Loader/index";
import { LOADER_HANDLER_TYPES } from "constants/index";
import { Form, Formik } from "formik";
import { openErrorToaster } from "helpers/toaster.helpers";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setShowWalletPopup } from "redux-thunk/redux/slices/wallet.slice";
import { getAllCurrencyStart } from "redux-thunk/thunk/gameSetting";
import { withdrawRequest } from "redux-thunk/thunk/user.thunk";
import {
  getAllBanks,
  getLiminalApproxFee,
  getWithdrawAmount,
} from "redux-thunk/thunk/wallet.thunk";
import { currencyIcon } from "utils/currencyIcon";
import { findPlainNumber, formatNumberWithCommas } from "utils/numberFormatter";
import { v4 as uuidv4 } from "uuid";
import { walletWithdrawSchema } from "../schema";

import CryptoComponent from "./CryptoComponent";

const AmountLabelComponent = memo((props) => {
  const { minMaxAmountLimit, depositCurrency } = props;
  const { t } = useTranslation();
  return typeof minMaxAmountLimit === "object" ? (
    <InputLabel className="input-label">
      {t(
        `Amount (Min ${formatNumberWithCommas(
          findPlainNumber(minMaxAmountLimit?.min)
        )} - Max ${formatNumberWithCommas(
          findPlainNumber(minMaxAmountLimit?.max)
        )} ) ${depositCurrency}`
      )}
      <Typography className="required-indicator">*</Typography>
    </InputLabel>
  ) : (
    <InputLabel className="input-label">
      {" "}
      {t(
        `Amount (Min ${formatNumberWithCommas(
          findPlainNumber(minMaxAmountLimit)
        )} ${depositCurrency})`
      )}{" "}
      <Typography className="required-indicator">*</Typography>
    </InputLabel>
  );
});

export const Withdraw = ({ currencyLimit, allCurrencies }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { depositData, allBanks, withdrawalFee, withdrawalFeeLoader } =
    useSelector((state) => state.wallet);
  // const { allCurrencies } = useSelector(state => state.gameSetting)
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [alertBoxopen, setAlertBoxOpen] = useState(false);
  const [connectedSocket, setConnectedSocket] = useState(false);
  const [walletCurrency, setWalletCurrency] = useState("default");

  const [error, setError] = useState({
    name: "",
    message: "",
    messageType: "error",
  });
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
    (state) => state.loader
  );

  const { [LOADER_HANDLER_TYPES.submit]: faitWithDrawloading } = useSelector(
    (state) => state.loader
  );
  const MIN_MAX_WITHDRAW_AMOUNT = currencyLimit?.value?.[walletCurrency];
  const userData = useSelector((state) => state?.user?.user?.getUser);
  // const { withdrawRequestData } = useSelector((state) => state?.user)
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setShowWalletPopup(false));
  };

  const onWithDrawError = (error) => {
    setAlertBoxOpen(true);
    if (error?.[0]?.name === "InternalServerError") {
      setError({
        name: error?.[0]?.name,
        message: t("internalServerError"),
        messageType: "error",
      });
    } else {
      setError({
        name: error?.[0]?.name,
        message: t(error?.[0]?.description),
        messageType: "error",
      });
    }
  };

  const onWithDrawSuccess = (response) => {
    if (response.message === "Insufficient balance") {
      setAlertBoxOpen(true);
      dispatch(setShowWalletPopup(true));
      setError({
        name: "InsufficientBalance",
        message: t("insufficientBalance"),
        messageType: "warning",
      });
    } else {
      handleOpen();
    }
  };

  const handleWithdrawRequest = async (values) => {
    setAlertBoxOpen(false);
    if (parseFloat(values.amount) >= MIN_MAX_WITHDRAW_AMOUNT) {
      try {
        const params1 = {
          currency: walletCurrency,
          amount: parseFloat(values.amount),
          address: values.address,
          fees: Number(withdrawalFee),
        };
        const params2 = {
          currency: "ETH",
          coin: walletCurrency,
          amount: parseFloat(values.amount),
          address: values.address,
          fees: Number(withdrawalFee),
        };
        dispatch(
          withdrawRequest({
            params: walletCurrency === "USDT" ? params2 : params1,
            onWithDrawError,
            onWithDrawSuccess,
          })
        );
      } catch (e) {
        openErrorToaster({ message: e.message });
      }
    } else {
      openErrorToaster({
        message: t("withdrawMinAmount", { min: MIN_MAX_WITHDRAW_AMOUNT }),
      });
    }
  };

  const handleAlertBoxClose = () => {
    setAlertBoxOpen(false);
  };

  const handleWalletSubmit = (values) => {
    const { bankName, mobileNo, amount, accountNo } = values;
    const reqBody = {
      amount: `${findPlainNumber(amount)}` || "",
      bankCode: bankName || "",
      name: userData?.username || "LoggedIn-User",
      mobile: mobileNo || "",
      bankAccount: `${accountNo}` || "",
    };
    dispatch(getWithdrawAmount(reqBody));
  };

  const handleKeyPress = (event) => {
    event.stopPropagation();
    const isNumeric = /^[0-9]$/;
    if (!isNumeric.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (!allCurrencies) {
      dispatch(getAllCurrencyStart());
    }
  }, []);

  const handleMaxValue = () => {
    const filterZeroAmountCurrency =
      userData?.userWallets &&
      userData?.userWallets?.filter(
        (currencyCodeDetails, index) =>
          currencyCodeDetails?.currency.code === walletCurrency
      );
    return filterZeroAmountCurrency[0].amount;
  };

  useEffect(() => {
    const result = allCurrencies?.filter(
      (currency) => currency?.code === walletCurrency
    );
    if (result.length === 0) return;
    setSelectedCurrency(...result);
    if (result?.[0]?.type === 1) {
      dispatch(getAllBanks());
    } else if (result?.[0]?.type === 0) {
      const reqBody = {
        currency: walletCurrency === "USDT" ? "ETH" : walletCurrency,
        coin: walletCurrency === "USDT" ? walletCurrency : "",
        onWithDrawError,
      };
      dispatch(getLiminalApproxFee(reqBody));
    }
  }, [walletCurrency]);

  const FaitComponent = () => {
    const conditions = { MIN_MAX_WITHDRAW_AMOUNT };
    return (
      <Box sx={{ ml: 2 }}>
        <Formik
          enableReinitialize
          initialValues={{
            bankName: "default",
            mobileNo: "",
            amount: "",
            accountNo: "",
          }}
          validationSchema={walletWithdrawSchema(t, conditions)}
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={handleWalletSubmit}
        >
          {({ values, handleChange, handleSubmit, handleBlur }) => (
            <Form autoComplete="off">
              <Grid container spacing={2} className="login-form-group">
                <Grid item xs={12}>
                  <AmountLabelComponent
                    minMaxAmountLimit={MIN_MAX_WITHDRAW_AMOUNT}
                    depositCurrency={walletCurrency}
                  />
                  <CustomTextField
                    type="text"
                    className="form-control shadow-none"
                    placeholder={t("enterAmount")}
                    name="amount"
                    value={values?.amount === "0" ? "" : values?.amount}
                    onChange={(e) => {
                      e.target.value = formatNumberWithCommas(
                        findPlainNumber(e.target.value)
                      );
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="amount"
                    component="div"
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                  />
                </Grid>

                <Grid item xs={12} key={uuidv4()}>
                  <Box>
                    <InputLabel className="input-label" htmlFor="bankName">
                      {t("bankname")}{" "}
                      <Typography className="required-indicator">*</Typography>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.bankName}
                      name="bankName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "100%", color: "#FFF" }}
                    >
                      <MenuItem value="default" disabled>
                        {t("selectBank")}
                      </MenuItem>
                      {allBanks?.banks?.map((menuItem) => (
                        <MenuItem value={menuItem?.code} key={uuidv4()}>
                          {currencyIcon[menuItem?.code]} &nbsp;{" "}
                          {t(menuItem?.name)}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="bankName"
                      component="div"
                      className="color-red text-danger error-msg login-input ms-2 d-flex"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel className="input-label">
                    {t("mobileNo")}
                  </InputLabel>
                  <CustomTextField
                    type="text"
                    className="form-control shadow-none"
                    placeholder="Enter Mobile"
                    name="mobileNo"
                    value={values.mobileNo}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="mobileNo"
                    component="div"
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="input-label">
                    {t("accountNumber")}{" "}
                    <Typography className="required-indicator">*</Typography>{" "}
                  </InputLabel>
                  <CustomTextField
                    type="text"
                    className="form-control shadow-none"
                    placeholder={t("enterAccountNumber")}
                    name="accountNo"
                    value={values.accountNo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="accountNo"
                    component="div"
                    className="color-red text-danger error-msg login-input ms-2 d-flex"
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomMainButton
                    type="submit"
                    fullWidth
                    disabled={loading}
                    sx={{ mb: 4 }}
                  >
                    {faitWithDrawloading ? (
                      <Loader variant={LOADER_HANDLER_TYPES.submit} />
                    ) : (
                      <span>{t("accountMenuWithdraw")}</span>
                    )}
                  </CustomMainButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };

  // const CryptoComponent = () => {
  //   const conditions = {
  //     MIN_MAX_WITHDRAW_AMOUNT,
  //     address: depositData?.address
  //   }
  //   return (
  //     <> {(walletCurrency && walletCurrency !== 'default') &&
  //       <Box sx={{ ml: 2 }}>
  //         <Formik
  //           enableReinitialize
  //           initialValues={{ address: '', amount: '' }}
  //           validationSchema={walletWithdrawCryptoSchema(t, conditions)}
  //           validateOnMount
  //           validateOnBlur
  //           validateOnChange
  //           onSubmit={handleWithdrawRequest}
  //         >
  //           {({ values, handleChange, handleSubmit, handleBlur }) => (
  //             <Form autoComplete='off'>
  //               <Grid container spacing={2} className='login-form-group'>
  //                 <Grid item xs={12}>
  //                   <InputLabel className='input-label'>
  //                     {t('Address')}
  //                   </InputLabel>
  //                   <CustomTextField
  //                     type='text'
  //                     className='form-control shadow-none'
  //                     placeholder={t('pasteYourCurrencyAddressHere', { currency: walletCurrency })}
  //                     name='address'
  //                     id='address'
  //                     value={values.address}
  //                     onChange={(e) => {
  //                       alertBoxopen && setAlertBoxOpen(false)
  //                       handleChange(e)
  //                     }}
  //                   // onBlur={handleBlur}
  //                   />
  //                   <ErrorMessage name='address' component='div' className='color-red text-danger error-msg login-input ms-2 d-flex' />
  //                 </Grid>
  //                 <Grid item xs={12}>
  //                   <InputLabel className='input-label'>
  //                     {t(`Amount (Min. ${MIN_MAX_WITHDRAW_AMOUNT} ${walletCurrency})`)}
  //                     <Typography className='required-indicator'>*</Typography>
  //                   </InputLabel>
  //                   <div className="input-with-button">
  //                     <CustomTextField
  //                       type='number'
  //                       style={{ marginBottom: '0px' }}
  //                       className='form-control shadow-none'
  //                       placeholder='Enter Amount'
  //                       name='amount'
  //                       value={values.amount}
  //                       onChange={(e) => {
  //                         alertBoxopen && setAlertBoxOpen(false)
  //                         handleChange(e)
  //                       }}
  //                       onBlur={handleBlur}
  //                     />
  //                     <button onClick={() => values.amount = handleMaxValue()}>Max</button>
  //                   </div>
  //                   <ErrorMessage name='amount' component='div' className='color-red text-danger error-msg login-input ms-2 d-flex' />
  //                 </Grid>
  //                 <Box className='address-submit-box'>
  //                   <List className='wallet-list'>
  //                     <ListItem sx={{ p: 0 }}>
  //                       <ListItemText
  //                         primary={t(`Please ensure that you do NOT input BEP2, BEP20 (BSC) address as a ${walletCurrency} withdraw address wallets`)}
  //                       />
  //                     </ListItem>
  //                     <ListItem sx={{ p: 0 }}>
  //                       <ListItemText
  //                         primary='Please do NOT withdraw as cross-chain transfer'
  //                       />
  //                     </ListItem>
  //                   </List>

  //                   <CustomMainButton
  //                     type='submit'
  //                     // onClick={handleSubmit(handleWithdrawRequest)}
  //                     fullWidth
  //                     disabled={loading}
  //                     sx={{ mb: 4 }}
  //                   >
  //                     {loading ? <Loader variant={LOADER_HANDLER_TYPES.submit} /> : <span>{t('withdrawWithdrawBtn')}</span>}
  //                   </CustomMainButton>
  //                   {
  //                     withdrawalFeeLoader
  //                       ? <CircularProgressBar />
  //                       : !isNaN(withdrawalFee) && (
  //                         <Typography
  //                           component='p'
  //                           textAlign='center'
  //                           className='wallet-bottom-text'
  //                         >
  //                           {t('Withdrawal Fee')}: <Box component='span' className='text-white'>{t(`${withdrawalFee} ${walletCurrency}`)}</Box>
  //                         </Typography>
  //                       )
  //                   }

  //                   {alertBoxopen &&
  //                     (
  //                       <Grid item xs={12} mt={2}>
  //                         <Box className='custom-alert-box'>
  //                           <AlertBox
  //                             open={alertBoxopen}
  //                             handleClose={handleAlertBoxClose}
  //                             messageType={error?.messageType}
  //                             message={error?.message}
  //                           />
  //                         </Box>
  //                       </Grid>
  //                     )}
  //                 </Box>
  //               </Grid>
  //             </Form>)}
  //         </Formik>
  //       </Box>}
  //     </>
  //   )
  // }

  return (
    <>
      <Grid container spacing={2} className="wallet-form-group">
        <Grid item xs={12}>
          <Box>
            <InputLabel className="input-label" htmlFor="currency">
              {t("Currency")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={walletCurrency}
              name="currency"
              onChange={(e) => {
                alertBoxopen && setAlertBoxOpen(false);
                setWalletCurrency(e.target?.value);
              }}
              sx={{ width: "100%", color: "#FFF" }}
            >
              <MenuItem value="default" disabled>
                {t("Select Your Currency")}
              </MenuItem>
              {allCurrencies?.map((menuItem) => {
                return (
                  <MenuItem
                    value={menuItem?.code}
                    style={{ display: "flex", alignItems: "center" }}
                    key={uuidv4()}
                    disabled={menuItem?.code === "IDR"}
                  >
                    {currencyIcon[menuItem?.code]} &nbsp;{t(menuItem?.code)}{" "}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          {walletCurrency === "USDT" && (
            <Grid>
              <InputLabel className="input-label" htmlFor="network">
                Network
              </InputLabel>
              <Select
                value={1}
                defaultValue={1}
                sx={{ width: "100%", color: "#FFF" }}
                readonly
              >
                <MenuItem value={1}>Ethereum(ERC-20)</MenuItem>
              </Select>
            </Grid>
          )}
        </Grid>

        <CryptoComponent
          MIN_MAX_WITHDRAW_AMOUNT={MIN_MAX_WITHDRAW_AMOUNT}
          depositData={depositData}
          handleWithdrawRequest={handleWithdrawRequest}
          handleMaxValue={handleMaxValue}
          alertBoxopen={alertBoxopen}
          setAlertBoxOpen={setAlertBoxOpen}
          loading={loading}
          withdrawalFee={withdrawalFee}
          error={error}
          withdrawalFeeLoader={withdrawalFeeLoader}
          walletCurrency={walletCurrency}
          handleAlertBoxClose={handleAlertBoxClose}
        />
        {/* {selectedCurrency?.type === 0 && <CryptoComponent />} */}
        {/* {selectedCurrency?.type === 1 && <FaitComponent />} */}
      </Grid>
      <AlertPopup
        open={open}
        onClose={handleClose}
        onConfirm={handleClose}
        message={t("withdrawApprovalReqMsg")}
        type="success"
      />
    </>
  );
};
