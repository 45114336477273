import { createAsyncThunk } from '@reduxjs/toolkit'
import { getSiteDataService, getSiteSettingDataService } from 'network/services/general.service';
import { getSettingDataSuccess, getSiteDataSuccess } from 'redux-thunk/redux/slices/general.slice';

export const getSiteData = createAsyncThunk(
  '/getSiteData',
  async (data, thunkApi) => {
    try {
      const res = await getSiteDataService()
      thunkApi.dispatch(getSiteDataSuccess(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)


export const getSiteSettingData = createAsyncThunk(
  '/getSiteSettingData',
  async (data, thunkApi) => {
    try {
      const res = await getSiteSettingDataService()
      thunkApi.dispatch(getSettingDataSuccess(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
