import { Box, styled } from '@mui/material'

export const ToasterWrapper = styled(Box)(({ theme }) => {
  return {

    '& .toasterSection': {
      backgroundColor: '#FFF', // '#201C29',
      color: '#000', // '#FFF',
      padding: '1.5rem',
      borderRadius: '8px',
      position: 'fixed',
      right: '20px',
      bottom: '20px',
      maxWidth: '25vw',
      display: 'flex',
      gap: '20px',
      alignItems: 'center',
      boxShadow: '0 0.8rem 3rem rgba(0,0,0,0.9)',
      '& p': {
        fontSize: '14px'
      },
      '& svg': {
        cursor: 'pointer'
      }
    }
  }
})
