import { TextField, styled } from '@mui/material'

// export const CustomFormWrapper = styled(Box)(({ theme }) => {
//     return {
//     };
// });

export const CustomSelect = styled(TextField)(({ theme }) => {
  return {
    width: '100%',
    borderRadius: theme.spacing(1),
    background: '#0d1b32',
    color: '#FFF',
    marginBottom: '5px',
    '& .MuiInputBase-root': {
      maxHeight: theme.spacing(7),
      borderRadius: theme.spacing(1.25),
      '& .MuiInputBase-input': {
        color: theme.palette.primary.contrastText,
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightRegular,
        padding: theme.spacing(2.25, 2),
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.contrastText
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.border.transparent
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.green
        }
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.transparent
        }
      },
      '&.Mui-focused, &.Mui-focused:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.green
        }
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.secondary.transparent,
        cursor: 'no-drop'
      }
    },
    ".MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: 'transparent',
      color: theme.palette.lightShade.light_09
    }

  }
})
