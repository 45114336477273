import Select from 'react-select'

export const ReactSelect = (props) => {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: '#000000bf', borderColor: '#000000bf', height: '52px', marginTop: '2px', borderRadius: '8px', outline: 'none' }),
    option: (styles) => ({ ...styles, backgroundColor: '#121C27', color: 'white', borderColor: '#121C27' }),
    singleValue: (styles) => ({ ...styles, color: 'white' }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#0096b0'
      }
    }
  }
  return (
    <>
      <Select
        {...props}
        styles={colourStyles}
      />
    </>
  )
}
