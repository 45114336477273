import { Box, styled } from '@mui/material'

export const FooterWrapper = styled(Box)(({ theme }) => {
  return {
    backgroundColor: '#0b0116',
    paddingTop: '5rem',
    maxWidth: '100%',
    margin: 'auto 0 0 0',
    '& .footer-container': {
      backgroundColor: '#0b0116',
      margin: '0 auto',
      maxWidth: '1200px',
      padding: theme.spacing(2.5, 0),
      [theme.breakpoints.down('xl')]: {
        padding: theme.spacing(2.5, 0)
      },
      '& .logo-wrap': {
        padding: theme.spacing(2.5, 12, 2.5, 0),
        [theme.breakpoints.down('xl')]: {
          paddingRight: 1
        },
        '& .logo-box': {
          '& .MuiTypography-body1': {
            color: '#fff',
            fontSize: theme.spacing(4.5),
            marginTop: 0,
            fontWeight: theme.typography.fontWeightSemiBold,
            [theme.breakpoints.down('xl')]: {
              fontSize: theme.spacing(2)
            },
            [theme.breakpoints.down('md')]: {
              fontSize: theme.spacing(2.5)
            }
          }
        },
        '& .MuiTypography-body1': {
          color: '#667FA4',
          fontSize: theme.spacing(1.5),
          fontWeight: theme.typography.fontWeightRegular,
          marginTop: theme.spacing(1.25),
          textAlign: 'justify',
          [theme.breakpoints.down('xl')]: {
            fontSize: theme.spacing(1.5)
          }
        },
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(2.5, 0, 2.5, 0)
        }
      },
      '& .footer-link-box': {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2.5, 0),
        '& .footer-link-in': {
          [theme.breakpoints.down('md')]: {
            marginBottom: '1.5rem'
          }
        },
        '& .MuiTypography-h6': {
          color: theme.palette.primary.contrastText,
          fontSize: theme.spacing(2.2),
          fontWeight: theme.typography.fontWeightSemiBold,
          marginBottom: theme.spacing(2),
          [theme.breakpoints.down('xl')]: {
            fontSize: theme.spacing(2)
          }

        },
        '& .MuiList-root': {
          padding: theme.spacing(0),
          '& .MuiListItem-root': {
            marginBottom: theme.spacing(0.2),
            '& .MuiButtonBase-root': {
              padding: theme.spacing(0),

              '& .MuiTypography-root': {
                color: '#667FA4',
                fontSize: theme.spacing(1.5),
                fontWeight: theme.typography.fontWeightMedium,
                transition: '0.3s all',
                [theme.breakpoints.down('xl')]: {
                  fontSize: theme.spacing(1.5)
                },

                '&:hover': {
                  color: theme.palette.button.activeBtn
                }
              },
              '&:hover': {
                backgroundColor: 'transparent'
              }

            }
          }
        },
        '&.social-link-box': {
          '& .MuiList-root': {
            padding: theme.spacing(0),
            marginBottom: theme.spacing(0),
            '& .MuiButtonBase-root': {
              display: 'inline-block',
              padding: theme.spacing(0),
              marginRight: theme.spacing(1),
              '& .MuiListItemIcon-root': {
                minWidth: 'unset',
                '& img': {
                  width: theme.spacing(2.5),
                  height: theme.spacing(2.5)
                }
              },
              '&:hover': {
                backgroundColor: theme.palette.primary.transparent

              }
            }
          }
        },
        '& .contact-card-wrap': {
          '& .contact-card': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
            '& .MuiTypography-body1': {
              color: '#667FA4',
              fontSize: theme.spacing(1.5),
              fontWeight: theme.typography.fontWeightMedium,
              [theme.breakpoints.down('xl')]: {
                fontSize: theme.spacing(1.5)
              },
              [theme.breakpoints.down('md')]: {
                fontSize: theme.spacing(1.5)
              }
            },
            '& .MuiLink-root': {
              fontSize: theme.spacing(1.75),
              paddingLeft: theme.spacing(1),
              textDecoration: 'none',
              transition: '0.3s all',
              color: theme.palette.primary.contrastText,
              [theme.breakpoints.down('xl')]: {
                fontSize: theme.spacing(1.5)
              },
              '&:hover': {
                color: theme.palette.button.activeBtn
              }
            }
          }
        }
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 2, 0)
      }
    },
    '& .img-license': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .line': {
      width: '100%',
      height: '1px',
      backgroundColor: '#30538E'
    },
    '& .copyright-container': {
      margin: '3rem 0',
      '& .MuiTypography-root': {
        color: theme.palette.primary.contrastText,
        fontSize: theme.spacing('0.875rem'),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: 'center',
        margin: theme.spacing(0),
        [theme.breakpoints.down('xl')]: {
          fontSize: theme.spacing(1.2)
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0)
    },
    '& .image-crypto-network': {
      margin: '3rem 0',
      backgroundColor: '#0b0116',
      flexWrap: 'wrap',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '2rem',
      '& img': {
        width: '120px',
      },

    }
  }
})
