import ErrorBoundary from 'components/layout/ErrorBoundary/index'
// import Loader from 'components/ui-kit/Loader/index'
import MainLoader from 'components/ui-kit/Loader/MainLoader/index'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Suspense, useEffect } from 'react'
import { Provider } from 'react-redux'
import store from 'redux-thunk/store'
import Routes from 'routes/index'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from './theme/index'
import { IndexStyled } from 'style'
import config from 'config/app.config'
import { useTranslation } from 'react-i18next'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function App () {
  const { t } = useTranslation()
  const errorBoundaryMessage = {
    title: t('ooops'),
    subtitle: t('signinTryAgain'),
    buttonTitle: t('refreshYourPage')

  }
  useEffect(() => {
    Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve }))).then(() => {
      // document.querySelector('.loading-page').style.display = 'none'
    })
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={config.socialLogin.google.clientId}>
        <CssBaseline />
        <Suspense fallback={<MainLoader />}>
          <Provider store={store}>
            <IndexStyled>
              <ErrorBoundary errorBoundaryMessage={errorBoundaryMessage}>
                <ToastContainer
                  position='top-right'
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme='colored'
                />
                <Routes />
              </ErrorBoundary>
            </IndexStyled>
          </Provider>
        </Suspense>
      </GoogleOAuthProvider>
    </ThemeProvider>
  )
}

export { store }

export default App
