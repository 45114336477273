import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { CustomMainButton } from "components/layout/Common/StyledButton/CustomButton";
import { CustomTextField } from "components/layout/Common/StyledForm/style";
import { CustomSelect } from "components/layout/Common/StyledSelect/style";
import ErrorMessage from "components/layout/ErrorMessage/index";
import { ReactSelect } from "components/molecules/ReactSelect/index";
import AlertBox from "components/Popup/AlertBox";
import { LOADER_HANDLER_TYPES } from "constants/index";
import SocialMediaLogin from "pages/Login/SocialLogin/SocialMediaLogin";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { CiFlag1, CiUser } from "react-icons/ci";
import { IoKeyOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { PiLineVerticalLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setAuthTabPanelValue } from "redux-thunk/redux/slices/auth.slice";
import { getAllCountriesStart } from "redux-thunk/thunk/gameSetting";
import { monthValues } from "utils/constant";
import { useStyles } from "../style";

const AuthStep = (props) => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setAcceptConditions,
    acceptConditions,
    errors,
    apiError,
    alertBoxopen,
    setAlertBoxOpen,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { allCountries } = useSelector((state) => state.gameSetting);
  const { [LOADER_HANDLER_TYPES.submit]: isCheckLoading } = useSelector(
    (state) => state.loader
  );

  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [copyPaste, setCopyPaste] = useState({
    password: false,
    confirmPassword: false,
  });
  const [isReferralCode, setIsReferralCode] = useState(false);
  const [isValidReferralCode, setIsValidReferralCode] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const affcCode = searchParams.get("affc");

  const [referCursorPosition, setReferCursorPosition] = useState(null);

  const referHandleCursorPosition = (e) => {
    setReferCursorPosition(e.target.selectionStart);
  };
  const handleAlertBoxClose = () => {
    setAlertBoxOpen(false);
  };

  const referHandleChange = (e) => {
    const { value, selectionStart } = e.target;
    const newValue = value?.replace(" ", "").toUpperCase()?.trim();
    const newPosition =
      referCursorPosition !== null
        ? selectionStart + (newValue.length - value.length)
        : null;
    e.target.value = newValue;
    setFieldValue("referralCode", e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
    if (newPosition !== null) {
      e.target.setSelectionRange(newPosition, newPosition);
      setReferCursorPosition(newPosition);
    }
  };

  useEffect(() => {
    dispatch(getAllCountriesStart());
  }, [dispatch]);

  const getCountryObj = (countryCode) => {
    const selCountry = allCountries?.rows?.find(
      ({ name, code }) => code === countryCode
    );
    const selectedCountry = selCountry
      ? { label: selCountry?.name, value: selCountry?.code }
      : "";
    return selectedCountry;
  };

  const checkCopyPaste = (event) => {
    const { name } = event.target;
    event.preventDefault();
    setCopyPaste((prev) => {
      return {
        ...prev,
        [name]: true,
      };
    });
  };

  const validateReferralCode = (referralcode) => {
    const isRef = referralcode?.slice(0, 3)?.toUpperCase() === "REF";
    const isDueces =
      referralcode
        ?.split("")
        ?.reverse()
        ?.join("")
        ?.slice(0, 6)
        ?.split("")
        ?.reverse()
        ?.join("")
        ?.toUpperCase() === "DEUCES";
    const isNumber = referralcode?.slice(3, referralcode?.length - 6);
    const isUserId =
      !isNaN(isNumber) &&
      isNumber !== "" &&
      Number(isNumber) !== 0 &&
      isNumber.charAt(0) !== "0";
    setIsValidReferralCode(isRef && isDueces && isUserId);
  };
  const isBothPasswordMatch =
    values?.password === values.confirmPassword &&
    values?.password.length >= 8 &&
    values.password !== "" &&
    values.confirmPassword !== "";

  useEffect(() => {
    values?.password && setCopyPaste({ ...copyPaste, password: false });
    values?.confirmPassword &&
      setCopyPaste({ ...copyPaste, confirmPassword: false });
  }, [values?.password, values?.confirmPassword]);

  useEffect(() => {
    if (values?.day || values?.month || values?.year) {
      setFieldValue(
        "dateOfBirth",
        `${values?.month}/${values?.day}/${values?.year}`
      );
      console.log(`${values?.month}/${values?.day}/${values?.year}`)
    }
  }, [setFieldValue, values?.day, values?.month, values?.year]);

  useEffect(() => {
    if (!isReferralCode) {
      setFieldValue("referralCode", "");
    }
  }, [isReferralCode, setFieldValue]);

  useEffect(() => {
    validateReferralCode(values?.referralCode);
  }, [values?.referralCode]);

  return (
    <>
      <>
        <Grid container spacing={0.8} className="login-form-group">
          <Grid item lg={12} xs={12}>
            <InputLabel
              className={classes?.inputLabel}
              htmlFor="Enter Username"
            >
              {t("Username")}{" "}
              <Typography className="required-indicator">*</Typography>
            </InputLabel>

            <CustomTextField
              name="username"
              type="text"
              id="userName"
              placeholder={t("userNamePlaceholder")}
              className="form-control shadow-none"
              value={values.username}
              onChange={(e) => {
                alertBoxopen && setAlertBoxOpen(false);
                e.target.value = e.target.value.trim();
                handleChange(e);
              }}
              onBlur={handleBlur}
              maxLength={50}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CiUser style={{ color: "#667FA4" }} edge="start" />
                    <PiLineVerticalLight
                      style={{ color: "#667FA4" }}
                      edge="start"
                    />
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage
              component="div"
              name="username"
              key="username"
              className="error-message"
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <InputLabel
              className={classes?.inputLabel}
              htmlFor="Enter Useremail"
            >
              {t("profileEmail")}{" "}
              <Typography className="required-indicator">*</Typography>
            </InputLabel>
            <CustomTextField
              name="email"
              type="text"
              placeholder={t("enterYourEmail")}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={values.email}
              onChange={(e) => {
                alertBoxopen && setAlertBoxOpen(false);
                e.target.value = e.target.value.trim();
                handleChange(e);
              }}
              onBlur={handleBlur}
              maxLength={50}
              haslabel="yes"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdOutlineAlternateEmail
                      style={{ color: "#667FA4" }}
                      edge="start"
                    />
                    <PiLineVerticalLight
                      style={{ color: "#667FA4" }}
                      edge="start"
                    />
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage
              component="div"
              name="email"
              key="email"
              className="error-message"
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <InputLabel
              className={classes?.inputLabel}
              htmlFor="Enter Username"
            >
              {t("profilePassword")}{" "}
              <Typography className="required-indicator">*</Typography>
            </InputLabel>
            <CustomTextField
              name="password"
              id="exampleInputPassword1"
              type={showPassword?.password ? "text" : "password"}
              placeholder={t("passwordPlaceholder")}
              value={values.password}
              onChange={(e) => {
                alertBoxopen && setAlertBoxOpen(false);
                e.target.value = e.target.value.trim();
                setCopyPaste((prev) => {
                  return {
                    ...prev,
                    password: false,
                  };
                });
                handleChange(e);
              }}
              onBlur={handleBlur}
              maxLength={50}
              haslabel="yes"
              sx={{
                border:
                  isBothPasswordMatch && !errors?.password && "1px solid green",
              }}
              onPaste={checkCopyPaste}
              onCopy={checkCopyPaste}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IoKeyOutline style={{ color: "#667FA4" }} edge="start" />
                    <PiLineVerticalLight
                      style={{ color: "#667FA4" }}
                      edge="start"
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword?.password,
                        })
                      }
                      edge="end"
                    >
                      {showPassword?.password ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage
              component="div"
              name="password"
              key="password"
              className="error-message"
            />
            <Typography sx={{ color: "red", fontSize: 13.5 }}>
              {copyPaste?.password && t("copyPasteNot")}
            </Typography>
          </Grid>

          <Grid item lg={12} xs={12}>
            <InputLabel
              className={classes?.inputLabel}
              htmlFor="Enter Username"
            >
              {t("signupConfirm") + " " + t("signupPassword")}{" "}
              <Typography className="required-indicator">*</Typography>
            </InputLabel>
            <CustomTextField
              name="confirmPassword"
              type={showPassword?.confirmPassword ? "text" : "password"}
              id="exampleInputPassword2"
              value={values.confirmPassword}
              placeholder={t("confirmPasswordPlaceholder")}
              onChange={(e) => {
                alertBoxopen && setAlertBoxOpen(false);
                e.target.value = e.target.value.trim();
                setCopyPaste((prev) => {
                  return {
                    ...prev,
                    confirmPassword: false,
                  };
                });
                handleChange(e);
              }}
              onBlur={handleBlur}
              maxLength={50}
              haslabel="yes"
              onPaste={checkCopyPaste}
              onCopy={checkCopyPaste}
              sx={{
                border:
                  isBothPasswordMatch && !errors?.password && "1px solid green",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IoKeyOutline style={{ color: "#667FA4" }} edge="start" />
                    <PiLineVerticalLight
                      style={{ color: "#667FA4" }}
                      edge="start"
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword?.confirmPassword,
                        })
                      }
                      edge="end"
                    >
                      {showPassword?.confirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage
              component="div"
              name="confirmPassword"
              key="confirmPassword"
              className="error-message"
            />
            <Typography sx={{ color: "red", fontSize: 13.5 }}>
              {copyPaste?.confirmPassword && t("copyPasteNot")}
            </Typography>
          </Grid>

          <Grid item lg={12} xs={12}>
            <InputLabel
              className={classes?.inputLabel}
              htmlFor="Enter Username"
            >
              {t("profileDob")}
              <Typography className="required-indicator">*</Typography>
            </InputLabel>
          </Grid>

          <Grid item lg={6} xs={12}>
            <CustomTextField
              name="day"
              type="number"
              id="day"
              placeholder={t("day")}
              className="form-control shadow-none"
              value={values?.day ? Number(values.day) : undefined}
              onChange={(e) => {
                alertBoxopen && setAlertBoxOpen(false);
                const value = e.target.value;
                setFieldValue("day", String(value).padStart(2, "0"));
              }}
              onBlur={handleBlur}
              maxLength={50}
            />

            <ErrorMessage
              component="div"
              name="day"
              className="color-red text-danger error-msg login-input  ms-2 d-flex"
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <CustomSelect
              select
              name="month"
              type="number"
              id="month"
              // label={t("month")}
              className="form-control shadow-none"
              value={Number(values?.month || 0)}
              onChange={(e) => {
                alertBoxopen && setAlertBoxOpen(false);
                let value = e.target.value;
                if (value === 0) return;

                setFieldValue("month", String(value).padStart(2, "0"));
              }}
            >
              {monthValues.map((value, index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    disabled={value === 0}
                  >
                    {value}
                  </MenuItem>
                ))}
            </CustomSelect>

            <ErrorMessage
              component="div"
              name="month"
              className="color-red text-danger error-msg login-input  ms-2 d-flex"
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <CustomTextField
              name="year"
              type="number"
              id="year"
              placeholder={t("year")}
              className="form-control shadow-none"
              value={values?.year}
              onChange={(e) => {
                alertBoxopen && setAlertBoxOpen(false);
                const value = e.target.value;
                setFieldValue("year", value);
              }}
              onBlur={handleBlur}
              maxLength={50}
              min={1940}
              max={2006}
            />

            <ErrorMessage
              component="div"
              name="year"
              className="color-red text-danger error-msg login-input  ms-2 d-flex"
            />
          </Grid>
          <Grid lg={6} sm={12} sx={{ ml: 2 }}>
            <ErrorMessage
              component="div"
              name="dateOfBirth"
              className="color-red text-danger error-msg login-input  ms-2 d-flex"
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <InputLabel className="input-label" htmlFor="country">
              {t("signupCounty")}{" "}
              <Typography className="required-indicator">*</Typography>
            </InputLabel>
            <ReactSelect
              type="text"
              placeholder={t("selectCountry")}
              className={`form-control shadow-none ${classes.countryWrap}`}
              id="countryCode"
              name="countryCode"
              style={{ height: "40px" }}
              value={getCountryObj(values.countryCode)}
              options={allCountries?.rows?.map((item) => {
                const temp = { ...item };
                temp.label = temp.name;
                temp.value = temp.code;
                return temp;
              })}
              onChange={(selectedOption) => {
                setFieldValue("countryCode", selectedOption.value);
              }}
              onBlur={handleBlur}
              maxLength={50}
              haslabel="yes"
              labeltext={t("country")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CiFlag1 style={{ color: "#667FA4" }} edge="start" />
                    <PiLineVerticalLight
                      style={{ color: "#667FA4" }}
                      edge="start"
                    />
                  </InputAdornment>
                ),
              }}
            />

            <ErrorMessage
              component="div"
              name="countryCode"
              className="color-red text-danger error-msg login-input  ms-2 d-flex"
            />
          </Grid>
        </Grid>

        <Box>
          {affcCode ? (
            <Box className="custom-checkbox-group">
              <Tooltip title={t("isReferralCheckbox")} placement="right-end">
                <label htmlFor="check-box-group" className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="check-box-group"
                    className="custom-input"
                    onChange={(e) => setIsReferralCode(e.target.checked)}
                    disabled={affcCode}
                  />
                </label>
              </Tooltip>
              <span className="primary-dark-text">{t("isReferralCode")}</span>
            </Box>
          ) : (
            <Box className="custom-checkbox-group">
              <label htmlFor="check-box-group" className="custom-checkbox">
                <input
                  type="checkbox"
                  id="check-box-group"
                  className="custom-input"
                  onChange={(e) => setIsReferralCode(e.target.checked)}
                />
              </label>
              <span className="primary-dark-text">{t("isReferralCode")}</span>
            </Box>
          )}
          {isReferralCode && (
            <Grid item xs={12}>
              <InputLabel
                className={classes?.inputLabel}
                htmlFor="Enter Username"
              >
                {t("referralCode")}{" "}
                <Typography className="required-indicator">*</Typography>
              </InputLabel>

              {/* <CustomTextField
                  name='referralCode'
                  type='text'
                  id='referralCode'
                  placeholder={t('referralCode')}
                  className='form-control shadow-none'
                  value={values?.referralCode}
                  onChange={(e) => {
                    e.target.value = e.target.value?.trim()?.toUpperCase()
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                /> */}

              <CustomTextField
                {...props}
                value={values?.referralCode}
                onChange={referHandleChange}
                onFocus={referHandleCursorPosition}
                onClick={referHandleCursorPosition}
              />
              <Typography sx={{ color: "red", fontSize: 13.5 }}>
                {values?.referralCode === "" && t("requiredReferralCode")}
                {values?.referralCode !== "" &&
                  !isValidReferralCode &&
                  t("invalidReferralCode")}
              </Typography>
            </Grid>
          )}
          <Box className="custom-checkbox-group">
            <label htmlFor="check-box-group" className="custom-checkbox">
              <input
                type="checkbox"
                id="check-box"
                className="custom-input"
                checked={acceptConditions.termsAndConditions}
                onClick={() => {
                  if (acceptConditions?.termsAndConditions) {
                    setFieldValue("termsAndConditions", false);
                  } else {
                    setAlertBoxOpen(false);
                    setFieldValue("termsAndConditions", true);
                  }
                  setAcceptConditions((prev) => ({
                    ...prev,
                    termsAndConditions: !prev.termsAndConditions,
                  }));
                }}
              />
            </label>
            <span className="primary-dark-text">
              {t("iAgreeto")}
              <a
                style={{ color: "#fff" }}
                href="/cms/general-terms"
                target="_blank"
              >
                {t("footerTerms")}
              </a>{" "}
              &nbsp;
              {t("greaterThan18years")}
            </span>
          </Box>

          <Grid item xs={12}>
            <AlertBox
              open={alertBoxopen}
              handleClose={handleAlertBoxClose}
              messageType="error"
              message={apiError?.message}
            />
          </Grid>
        </Box>
      </>

      <Box sx={{ mt: 2 }}>
        <CustomMainButton
          className="sign-up-btn"
          type="submit"
          onSubmit={handleSubmit}
          fullWidth
          disabled={
            (isReferralCode && values?.referralCode === "") ||
            (values?.referralCode !== "" && !isValidReferralCode) ||
            isCheckLoading
          }
          sx={{ color: "#fff" }}
          style={{ border: "none" }}
        >
          {isCheckLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            t("signinSignup")
          )}
        </CustomMainButton>
      </Box>

      {/* <Box className="or-divider">
        <span className="custom-divider">{t("or")}</span>
      </Box>
      <SocialMediaLogin /> */}

      <Box className="forgot-text2">
        <Typography className="primary-dark-text">
          {t("alreadyHave")}
        </Typography>{" "}
        <Link href="#" onClick={() => dispatch(setAuthTabPanelValue("1"))}>
          {t("login")}
        </Link>
      </Box>

      <Box className="captcha">
        <Typography className="text-captcha">{t("captcha")}</Typography>
      </Box>
    </>
  );
};

export default AuthStep;
