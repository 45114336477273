import { Typography } from "@mui/material";
import { memo } from "react";
import { formatPrice } from "utils/numberFormatter";

const AmountComponent = memo((props) => {
  const { option, additionalInfo, isNonCash } = props;

  const showValue = (option) => {
    switch (option?.code) {
      case "IDR":
        return Number(option?.amount) || 0;
      case "USD":
        return Number(option?.amount) || 0;
      default:
        return (
          Number(option?.amount) /
            Number(additionalInfo?.convertedRate?.[option?.code]) || 0
        );
    }
  };
  return (
    <>
      {!isNonCash ? (
        <Typography className="coin-value">
          {additionalInfo?.other?.displayCryptoInFlat
            ? formatPrice(showValue(option), true)
            : formatPrice(option?.amount)}
        </Typography>
      ) : (
        <Typography className="coin-value">
          {formatPrice(
            option?.nonCashAmount,
            additionalInfo?.other?.displayCryptoInFlat
          )}
        </Typography>
      )}
    </>
  );
});

export default AmountComponent;
