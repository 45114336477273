import { ROUTE_PATHS } from 'constants/index'
import { lazy } from 'react'

const Affiliate = lazy(() => import('pages/Affiliate'))
const Sponsorships = lazy(() => import('pages/Sponsorships/index'))
const Referral = lazy(() => import('pages/Referral/index'))
const Profile = lazy(() => import('pages/Profile'))
const ForgetPassword = lazy(() => import('pages/ForgetPassword'))
const About = lazy(() => import('pages/About'))
const Home = lazy(() => import('pages/Home'))
const Casino = lazy(() => import('pages/Casino'))
const Crash = lazy(() => import('pages/Crash'))
const Dice = lazy(() => import('pages/Dice'))
const Result = lazy(() => import('pages/Result'))
const MyBets = lazy(() => import('pages/MyBets'))
const FavoriteGames = lazy(() => import('pages/Casino/components/FavoriteGames'))
const Promotions = lazy(() => import('pages/Promotions/index'))
const PromotionsCasino = lazy(() => import('pages/Promotions/Casino/PromotionsCasino'))
const visitedCard = lazy(() => import('pages/Promotions/Components/visitedCard'))
const Cms = lazy(() => import('pages/Cms/index'))
const GameContainer = lazy(() => import('pages/GameContainer/index'))
const LoyaltyBonus = lazy(() => import('pages/Loyalty/index'))
const ComingSoonPage = lazy(() => import('components/layout/ComingSoon'))
const UsersProfile = lazy(() => import('pages/Profile/UsersProfile/index'))
const RespsibleGambling = lazy(() => import('pages/Profile/ResponsibleGambling/index'))
const Transaction = lazy(() => import('pages/Profile/Transactions/index'))
const VipClub = lazy(() => import('pages/Home/VipClub'))
const Bonus = lazy(() => import('pages/Bonus/index'))
const BonusDetailsPage = lazy(() => import('pages/Bonus/Components/BonusDetailsPage'))
const MyBonuses = lazy(() => import('pages/Profile/MyBonuses/index'))
const Providers = lazy(() => import('pages/Providers'))


const routesList = [
  {
    path: ROUTE_PATHS.HOME,
    // path: ROUTE_PATHS.TEMPHOME,
    // component: Home,
    component: Casino,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.LOGIN,
    showHeader: true,
    showFooter: true,
    // component: Home
    component: Casino
  },
  {
    path: ROUTE_PATHS.SIGNUP,
    showHeader: true,
    showFooter: true,
    // component: Home
    component: Casino
  },
  {
    path: ROUTE_PATHS.LOYALTY_BONUS_PAGE,
    component: LoyaltyBonus,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },

  {
    path: ROUTE_PATHS.CASINO_PLAYGAME,
    component: GameContainer,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.SEND_RESET_LINK,
    component: ForgetPassword,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    component: ForgetPassword,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.ABOUT,
    component: About,
    showHeader: true,
    showFooter: true
  },

  {
    path: [ROUTE_PATHS.CASINO, ROUTE_PATHS.GAME_CATAGORY],
    component: Casino,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PROVIDERS,
    component: Providers,
    isPrivate: false,
    showHeader: true,
    showFooter: true,
  },
  {
    path: ROUTE_PATHS.PROMOTION,
    component: Promotions,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.BONUS,
    component: Bonus,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },

  {
    path: ROUTE_PATHS.PROMOTIONLIST,
    component: PromotionsCasino,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PROMOTIONS_PAGE_ID,
    component: visitedCard,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },

  {
    path: ROUTE_PATHS.BONUS_PAGE_ID,
    component: BonusDetailsPage,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },


  {
    path: [
      ROUTE_PATHS.PROFILE,
      ROUTE_PATHS.BALANCE,
      ROUTE_PATHS.DEPOSIT,
      ROUTE_PATHS.WITHDRAWALS,
      ROUTE_PATHS.CASINO_BET,
      ROUTE_PATHS.SPORTS_BET,
      ROUTE_PATHS.LOYALTY,
      ROUTE_PATHS.REFERRALS,
      ROUTE_PATHS.SETTINGS,
      ROUTE_PATHS.TRANSACTIONS,
      ROUTE_PATHS.VERIFICATION,
      ROUTE_PATHS.LIMITS
    ],
    component: Profile,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.CMS,
    component: Cms,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.COMINGSOON,
    component: ComingSoonPage,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: [ROUTE_PATHS.MY_ACCOUNT, ROUTE_PATHS.MY_ACCOUNT_CHANGEPASSWORD, ROUTE_PATHS.MY_ACCOUNT_USERKYC, ROUTE_PATHS.MY_ACCOUNT_PRIVACY],
    component: UsersProfile,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.RESPONSIBLE_GAMBLING,
    component: RespsibleGambling,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.TRANSACTION,
    component: Transaction,
    isPrivate: true,
    showHeader: true,
    showFooter: false
  },
  {
    path: ROUTE_PATHS.CRASH,
    component: Crash,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.DICE,
    component: Dice,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.RESULT,
    component: Result,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.FAVORITES,
    component: FavoriteGames,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.MYBETS,
    component: MyBets,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.FAVORITE,
    component: FavoriteGames,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.SPORTS,
    component: ComingSoonPage,
    isPrivate: false,
    showHeader: true,
    showFooter: false
  },
  {
    path: ROUTE_PATHS.AFFILIATE,
    component: Affiliate,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.REFERRAL,
    component: Referral,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.SPONSORSHIP,
    component: Sponsorships,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.VIPCLUB,
    component: VipClub,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.MY_BONUSES,
    component: MyBonuses,
    isPrivate: true,
    showHeader: true,
    showFooter: false
  }
]

export default routesList
