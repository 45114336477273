import { Box, styled } from '@mui/material'
import checkIcon from '../../assets/icons/check.svg'
import { head } from 'lodash'
export const WalletModalStyle = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: theme.spacing(60),
    width: '100%',
    backgroundColor: '#0b0116',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    maxHeight: '100vh',
    overflowY: 'auto',

    '.input-with-button': {
      display: 'flex',
      alignItems: 'center',
    },

    '.box-flex-currency': {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #3b5364',
      'p': {
        marginTop: '0 !important',
        marginBottom: '.5rem',
        color: '#6c7b8e',
      }
    },

    '.boxestimatedUSD': {
      height: '42px'
    },

    '.estimatedUSD': {
      color: '#fff',
      fontSize: '22px',
      marginBottom: '1rem',
    },

    '.box-currency-': {
      marginTop: '.5rem'
    },

    '.box-height-currency': {
      height: '40px'
    },

    '.currency-user': {
      // backgroundColor: '#213743',
      borderRadius: '10px',
      padding: '20px 20px 0 20px',
      height: '190px',
      overflowY: 'auto'
    },

    '.currency': {
      marginTop: '.5rem',
    },

    '.currencyName': {
      display: 'flex',
      alignItems: 'center',
      gap: '.2rem',
      textAlign: 'left',
      fontSize: '13px'
    },

    '.amountCurrency': {
      textAlign: 'right',
    },

    '.amountcrypto': {
      fontSize: '13px'
    },

    '.amountperprice': {
      fontSize: '11px',
      color: '#6a798c',
    },

    '.marginamount': {
      marginBottom: '.5rem'
    },

    '.input-with-button:hover': {
      backgroundColor: 'transparent',
    },

    '.input-with-button button': {
      marginLeft: '10px',
      marginRight: '10px',
    },

    '& .login-modal': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .MuiTabs-indicator': {
        display: 'none'
      },

      '& .modal-heading': {
        textAlign: 'left',
        fontSize: theme.spacing(2.5),
        color: theme.palette.primary.contrastText
      },

      '& .login-tab': {
        marginTop: theme.spacing(3.5),
        '& .login-inner-tab': {
          width: '100%',
          '& button': {
            width: '100%',
            color: '#fff',
            textTransform: 'capitalize',
            fontSize: '14px',
            borderRadius: theme.spacing(0.625),
          },
          '.item-one': {
            width: '50%'
          },
          '.item-two': {
            width: '50%'
          },
          '& .Mui-selected': {
            color: 'rgb(255, 255, 255)',
            background: 'linear-gradient(90deg, rgb(63, 134, 252) 4%, rgb(13, 47, 110) 100%)',
          },
        },

        '& .tab-panel': {
          padding: theme.spacing(0),
        },

        '& .input-label': {
          color: theme.palette.primary.contrastText,
          fontSize: theme.spacing(2),
          fontWeight: theme.typography.fontWeightMedium,
          padding: theme.spacing(1),
          display: 'flex',
          '& .required-indicator': {
            color: 'red'
          }
        },
        '& .address-submit-box': {
          padding: theme.spacing(2)
        },

        '& .wallet-form-group': {
          color: theme.palette.primary.contrastTextDark,
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            border: '1px solid',
            borderColor: '#e6e6fa !important', // Important to override the default MUI style
          },
          '& .MuiInputBase-input': {
            color: theme.palette.primary.contrastText,
            border: '1px',
            // borderColor: 'transparent !important',
            borderColor: '#e6e6fa !important',
            display: 'flex',
            alignItems: 'center',
            '& fieldset': {
              borderColor: '#e6e6fa', // Ensure the border color is applied correctly
            },
            '&:hover fieldset': {
              borderColor: '#e6e6fa', // Border on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#e6e6fa', // Border on focus
            },
            '&.Mui-disabled fieldset': {
              borderColor: '#e6e6fa', // Ensure border color is consistent when disabled
            },
          },
          '& .QRCodeGenerator': {
            background: theme.palette.primary.contrastText,
            borderRadius: theme.spacing(1.625),
            display: 'inline-block',
            padding: theme.spacing(2),
            lineHeight: theme.spacing(0)
          },
          '& .loderBox': {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: theme.spacing(1.625)
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.primary.contrastText
          }
        },
        '& .custom-alert-box': {
          '& .MuiSvgIcon-root': {
            color: 'inherit'
          }
        },

        '& .custom-checkbox-group': {
          paddingTop: theme.spacing(2.5),
          display: 'flex',
          alignItems: 'flex-start',
          '& .primary-dark-text': {
            color: theme.palette.primary.contrastTextDark,
            fontSize: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(0)
          },

          '& .custom-checkbox': {

            '& .custom-input': {
              appearance: 'none',
              padding: theme.spacing(1.25),
              border: `1px solid ${theme.palette.primary.contrastTextDark}`,
              borderRadius: theme.spacing(0.5),
              cursor: 'pointer',

              ':checked': {
                backgroundImage: `url(${checkIcon})`
              }
            }

          }

        },

        '& .social-media-box': {
          gap: theme.spacing(1),
          marginTop: theme.spacing(2),
          '& .social-media-icon': {
            background: theme.palette.primary.iconBg,
            textAlign: 'center',
            padding: theme.spacing(1.6, 6),
            borderRadius: theme.spacing(0.625)
          }
        },
        '& .forgot-text': {
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.spacing(3.5),

          a: {
            color: theme.palette.primary.contrastText,
            fontSize: theme.spacing(2),
            fontWeight: theme.typography.fontWeightMedium
          }
        }

      },

      '.or-divider': {
        textAlign: 'center',
        padding: theme.spacing(3.5, 0, 2, 0),

        '& .custom-divider': {
          color: theme.palette.primary.contrastTextDark,
          padding: theme.spacing(0, 1),
          position: 'relative',
          '&::before': {
            content: '""',
            width: theme.spacing(10.25),
            height: theme.spacing(0.125),
            background: theme.palette.primary.contrastTextDark,
            position: 'absolute',
            top: '50%',
            left: '100%'
          },

          '&::after': {
            content: '""',
            width: theme.spacing(10.25),
            height: theme.spacing(0.125),
            background: theme.palette.primary.contrastTextDark,
            position: 'absolute',
            top: '50%',
            right: '100%'
          }
        }

      },

      '& .primary-dark-text': {
        color: theme.palette.primary.contrastTextDark,
        paddingRight: theme.spacing(1)
      },

      '.wallet-bottom-text, .wallet-list': {
        // color: theme.palette.primary.contrastTextDark
        color: theme.palette.primary.contrastText
      },

      '.text-white': {
        color: theme.palette.primary.white
      }

    },

    '& .theme-select': {
      '& .MuiList-root': {
        background: 'red'
      }
    }
  }
})
